<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <company-cost-form :dataBackgroundColor="'#484848'"></company-cost-form>
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Company Costs</h4>
            <p class="category">It displays company costs</p>
          </md-card-header>
          
          <md-card-content>
            <company-cost-table table-header-color="'#484848'"></company-cost-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyCostTable from "@/components/Tables/CompanyCostTable.vue";
import CompanyCostForm from "@/pages/CompanyCostForm.vue";

export default {
  components: {
    CompanyCostTable,
    CompanyCostForm,
  },
};
</script>

<style scoped>


.add-button {
  margin-bottom: 20px;
}
</style>