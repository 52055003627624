<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <extra-cost-form :dataBackgroundColor="'#484848'" :isNewExtraCost="true" :carType="carType" ></extra-cost-form>
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Extra Cost </h4>
            <p class="category">It displays Extra Costs </p>
          </md-card-header>
    
          <md-card-content>
            <extra-cost-table table-header-color="'#484848'"></extra-cost-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import ExtraCostTable from "@/components/Tables/ExtraCostTable.vue";
import ExtraCostForm from "@/pages/ExtraCostForm.vue";
import { API_URLS } from '@/apiConfig.js';

export default {
  components: {
    ExtraCostTable,
    ExtraCostForm,
  },
  data() {
    return {
      carType: null, // Initialize carType to null
    };
  },
  created() {
    this.fetchCarDetails(); // Fetch car details when the component is created
  },
  methods: {
    async fetchCarDetails() {
      try {
        // Your code to fetch car details
        const carId = this.$route.params.id;
        const token = localStorage.getItem('accessToken');
        const apiUrl = API_URLS.efeGetCarById(carId);
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch car details');
        }

        const data = await response.json();
        this.carType = data.car_type; // Set the fetched car_type to the data property
      } catch (error) {
        //console.error('Error fetching car details:', error.message);
      }
    },
  },
};
</script>

<style scoped>


.add-button {
  margin-bottom: 20px;
}
</style>
