<template>
  <form @submit.prevent="addUpdateCost">
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">{{ isNewExtraCost ? 'Add New Extra Cost' : 'Update Extra Cost Details' }}</h4>
        <p class="category">MAK</p>
      </md-card-header>
      <div class="empty-row"></div>
      <md-card-content>
        <div class="md-layout">
          <input type="hidden" v-model="car_id" />
          <!-- Cost Date -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label class="always-above">Cost Date</label>
              <md-input v-model="cost_date" type="date"></md-input>
            </md-field>
          </div>

          <div class="empty-row"></div>

          <!-- Cost -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Cost</label>
              <md-input v-model="cost" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Customer Price -->
          <div v-if="carType == 'For Service'" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Customer Price</label>
              <md-input v-model="customer_price" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Taken Money -->
          <div v-if="carType == 'For Service'" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Taken Money</label>
              <md-input v-model="taken_money" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Notes -->
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>Notes</label>
              <md-textarea v-model="notes" rows="3"></md-textarea>
            </md-field>
          </div>
          
          <!-- Submit Button -->
          <div class="md-layout-item md-size-100 text-right">
            <md-button type="submit" class="md-raised md-success add-button">{{ isNewExtraCost ? 'Add Extra Cost' : 'Update Extra Cost' }}</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "extra-cost-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "#484848",
    },
    isNewExtraCost: {
      type: Boolean,
      default: true,
    },
    carType: {
      type: String,
      default: '', // Default value in case it's not provided
    },
  },
  data() {
    return {
      car_id: null,
      cost_date: null,
      cost: null,
      customer_price: null,
      taken_money: null,
      notes: null,
    };
  },
  async created() {
    if (!this.isNewExtraCost) {
      // If it's not a new cost, fetch cost details for updating
      this.fetchCostDetails();
    }
  },
  methods: {
    async fetchCostDetails() {
      try {
        const id = this.$route.params.extracost_id;
        const car_id = this.$route.params.id;
        const token = localStorage.getItem('accessToken');
        
        const apiUrl=API_URLS.getExtraCostByExtraCostID(id)
        //console.log(apiUrl)
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch cost details');
        }
        
        const data = await response.json();

        // Populate costDetails with fetched data
        this.car_id = car_id;
        this.cost_date = data.cost_date;
        this.cost = data.cost;
        this.customer_price = data.customer_price;
        this.taken_money = data.taken_money;
        this.notes = data.notes;
      } catch (error) {
        //console.error('Error fetching cost details:', error.message);
      }
    },
    async addUpdateCost() {

    // Check if cost_date is blank or null
    if (!this.cost_date || this.cost_date === '') {
      this.$swal({
        title: 'Error',
        text: 'Date cannot be blank',
        icon: 'error',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      return;
    }

    // Check if cost is blank or null
    if (!this.cost || this.cost === '') {
      this.$swal({
        title: 'Error',
        text: 'Cost cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }


      const car_id = this.$route.params.id;
      const cost_id = this.$route.params.extracost_id;

      const token = localStorage.getItem('accessToken');
      try {
        let response;
        let apiUrl;
        let method;

        if (this.isNewExtraCost) {
          apiUrl = API_URLS.efeCreateNewExtraCost(car_id);
          method = 'POST';
        } else {
          apiUrl = API_URLS.efeUpdateCarExtraCost(cost_id);;
          method = 'PATCH';
        }

        response = await fetch(apiUrl, {
          method: method,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            car_id: car_id,
            cost_date: this.cost_date,
            cost: this.cost,
            customer_price: this.customer_price,
            taken_money: this.taken_money,
            notes: this.notes,
          }),
        });

        if (!response.ok) {
          throw new Error(this.isNewExtraCost ? 'Failed to add extra cost' : 'Failed to update extra cost');
        }

        const data = await response.json();
        //console.log(this.isNewExtraCost ? 'Extra cost added successfully:' : 'Extra cost updated successfully:', data);

        //console.log(this.isNewExtraCost)
        if(this.isNewExtraCost){
          this.$swal({
            title: 'Success',
            text: 'Extra cost added successfully!',
            icon: 'success',
            timer: 2000, // Dialog will close after 2 seconds
            showConfirmButton: false // Hide the confirm button
          });
          // Wait for 2 seconds before reloading the page
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }

        if(!this.isNewExtraCost){
          this.$swal({
            title: 'Success',
            text: 'Extra cost updated successfully!',
            icon: 'success',
            timer: 2000, // Dialog will close after 2 seconds
            showConfirmButton: false // Hide the confirm button
          });
          // Wait for 2 seconds before reloading the page
          setTimeout(() => {
            this.$router.push(`/extracost/${car_id}`);
          }, 2000);
          
        }
      } catch (error) {
        //console.error('Error:', error.message);
      }
    },
  },
};
</script>

<style scoped>
.always-above {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease; /* Optional: Add transition for smooth movement */
}

/* Target all buttons */
button {
  background-color: #484848; /* antrasit color */
}
/* Override button color with !important */
form .md-button.md-raised.md-success.add-button {
  background-color: #484848 !important; /* antrasit color */
}
.empty-row {
  height: 40px; /* Adjust the height as needed */
}
</style>
