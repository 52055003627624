<template>
  <div>
    <div class="search-bar">
      <input type="text" placeholder="Search..." v-model="searchQuery" style="margin-right: 10px;">
      <md-datepicker v-model="startDate" placeholder="Start Date" class="anthracite-datepicker"></md-datepicker>
      <md-datepicker v-model="endDate" placeholder="End Date" style="margin-right: 10px;"></md-datepicker>
      <!-- <md-button @click="applyDateRange" style="margin-right: 10px;">Apply</md-button> -->
    </div>
    
    <md-table v-model="currentPageCompanyCosts" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell>
          <md-button class="delete-icon" @click="deleteCompanyCost(item)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="Cost Date">{{ item.cost_date }}</md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="Cost">{{ item.cost }}</md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="Notes">{{ item.notes }}</md-table-cell>
      </md-table-row>
    </md-table>

    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
    
    <div class="total-cost">
      Total Cost (Euro): {{ totalCostForRange }}
    </div>
  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "CompanyCostTable",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      companyCosts: [],
      searchQuery: '',
      startDate: null,
      endDate: null,
      currentPage: 1,
      perPage: 10,
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
    };
  },
  computed: {
    filteredCompanyCosts() {
      return this.companyCosts.filter(cost => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        const withinDateRange = (!this.startDate || new Date(cost.cost_date) >= this.startDate) &&
                                (!this.endDate || new Date(cost.cost_date) <= this.endDate);
        return withinDateRange &&
               ((cost.cost_date && cost.cost_date.toString().includes(this.searchQuery)) ||
                (cost.cost && cost.cost.toString().includes(this.searchQuery)) ||
                (cost.notes && cost.notes.toLowerCase().includes(searchQueryLower)));
      });
    },
    totalPages() {
      return Math.ceil(this.filteredCompanyCosts.length / this.perPage);
    },
    currentPageCompanyCosts() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredCompanyCosts.slice(start, end);
    },
    totalCostForRange() {
      return this.filteredCompanyCosts.reduce((total, cost) => {
        if ((!this.startDate || new Date(cost.cost_date) >= this.startDate) &&
            (!this.endDate || new Date(cost.cost_date) <= this.endDate)) {
          return total + parseFloat(cost.cost);
        }
        return total;
      }, 0).toFixed(2);
    }
  },
  mounted() {
    this.fetchCompanyCosts();
  },
  methods: {
    async fetchCompanyCosts() {
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          //console.error('JWT token not found');
          return;
        }
        const apiUrl = API_URLS.companycosts;
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        this.companyCosts = data;
      } catch (error) {
        //console.error("Error fetching company costs:", error);
      }
    },
    async deleteCompanyCost(companyCost) {
  const id = companyCost.id;

  // Show confirmation dialog with SweetAlert
  const confirmation = await this.$swal({
    title: 'Confirmation',
    text: 'Are you sure you want to delete this company cost?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel',
  });

  if (!confirmation.isConfirmed) {
    return; // If user cancels deletion, exit method
  }

  const token = localStorage.getItem('accessToken');
  if (!token) {
    // Handle the absence of token, such as redirecting to login page or displaying an error message
    return;
  }

  const apiUrl = API_URLS.deletecompanycosts(id);
  try {
    const response = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to delete company cost');
    }

    // Show success message with SweetAlert
    await this.$swal({
      title: 'Success',
      text: 'Company cost deleted successfully!',
      icon: 'success',
      timer: 2000, // Dialog will close after 2 seconds
      showConfirmButton: false // Hide the confirm button
    });

    // Refresh the page after successful deletion
    setTimeout(() => {
      location.reload();
    }, 1000);
  } catch (error) {
    // Handle the error, such as displaying an error message to the user.
    //console.error('Error deleting company cost:', error);
  }
},

    notifyVue(message, verticalAlign, horizontalAlign) {
      var color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        message: message,
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
    applyDateRange() {
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        this.notifyVue("End date cannot be before start date", 'top', 'center');
        return;
      }
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
/* Add a class to target the table cells */
.table-cell {
  font-size: 80px !important; /* Set the desired font size */
}
.search-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center; /* Align items vertically */
  margin-bottom: 10px;
}

.search-bar input[type="text"] {
  padding: 8px;
  margin-right: 10px;
  border: 2px solid #484848; /* Changed color to orange */
  border-radius: 4px;
  outline: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: 2px solid #484848; /* Changed color to orange */
  background-color: #484848; /* Changed color to orange */
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #484848; /* Darker shade of orange */
}

.total-cost {
  margin-top: 10px;
  text-align: center;
}
.anthracite-datepicker .md-datepicker-input,
.anthracite-datepicker .md-datepicker-button {
  color: #333; /* Text color */
}

.anthracite-datepicker .md-datepicker-dialog {
  background-color: #333; /* Background color */
}

.anthracite-datepicker .md-datepicker-calendar {
  color: #fff; /* Calendar text color */
}

.anthracite-datepicker .md-datepicker-calendar .md-datepicker-date,
.anthracite-datepicker .md-datepicker-calendar .md-datepicker-month .md-datepicker-month-label,
.anthracite-datepicker .md-datepicker-calendar .md-datepicker-year .md-datepicker-year-label,
.anthracite-datepicker .md-datepicker-calendar .md-datepicker-header-button {
  color: #fff; /* Calendar date, month, year text color */
}

.anthracite-datepicker .md-datepicker-calendar .md-datepicker-day.md-datepicker-selected {
  background-color: #484848; /* Selected date background color */
}

.anthracite-datepicker .md-datepicker-calendar .md-datepicker-day.md-datepicker-outside {
  color: #666; /* Outside date text color */
}

.anthracite-datepicker .md-datepicker-calendar .md-datepicker-day.md-datepicker-outside.md-datepicker-selected {
  background-color: #484848; /* Selected outside date background color */
}

.anthracite-datepicker .md-datepicker-calendar .md-datepicker-actions {
  background-color: #333; /* Actions background color */
}

.anthracite-datepicker .md-datepicker-calendar .md-datepicker-actions button {
  color: #fff; /* Actions button text color */
}

.anthracite-datepicker .md-datepicker-calendar .md-datepicker-actions button:hover {
  background-color: #484848; /* Hover background color for actions button */
}
</style>
