<template>
  <form @submit.prevent="submitForm">
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">{{ isNewOwner ? 'Add New Owner' : 'Update Owner Details' }}</h4>
        <p class="category">MAK</p>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>First Name</label>
              <md-input v-model="owner.first_name" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Last Name</label>
              <md-input v-model="owner.last_name" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Phone</label>
              <md-input v-model="owner.phone" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Email</label>
              <md-input v-model="owner.email" type="email"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button type="submit" class="md-raised md-success">{{ isNewOwner ? 'Add Owner' : 'Update Owner' }}</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "OwnersForm",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "#484848",
    },
    isNewOwner: {
      type: Boolean,
      default: true,
    },
    ownerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      owner: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
    };
  },
  created() {
    if (!this.isNewOwner) {
      // Fetch owner details for update
      this.fetchOwnerDetails();
    }
  },
  methods: {
    async submitForm() {
    // Check if first_name and last_name are filled
    if (!this.owner.first_name.trim() || !this.owner.last_name.trim()) {
      this.$swal({
        title: 'Error',
        text: 'Please fill in the First Name and Last Name fields.',
        icon: 'error',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      return;
    }


      // Get the JWT token from wherever you have stored it (e.g., localStorage)
      const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

      try {
        //console.log(this.owner)
        const ownerId = this.$route.params.id;
        const apiUrl = this.isNewOwner ? API_URLS.addOwner : API_URLS.updateOwner(ownerId);
        const method = this.isNewOwner ? 'POST' : 'PATCH';

        const response = await fetch(apiUrl, {
          method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
          },
          body: JSON.stringify(this.owner),
        });

        if (!response.ok) {
          throw new Error(this.isNewOwner ? 'Failed to add owner' : 'Failed to update owner');
        }

        const data = await response.json();
        //console.log(this.isNewOwner ? 'Owner added successfully:' : 'Owner updated successfully:', data);
        this.$swal({
        title: 'Success',
        text: this.isNewOwner ? 'Owner added successfully!' : 'Owner updated successfully!',
        icon: 'success',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      // Wait for 2 seconds before reloading the page
      setTimeout(() => {
        this.$router.push('/owners'); // Assuming '/owners' is the route to the owners page
      }, 2000);
        
      } catch (error) {
        //console.error('Error:', error.message);
        // Handle error, such as displaying an error message to the user
      }
    },

    async fetchOwnerDetails() {
      // Get the JWT token from wherever you have stored it (e.g., localStorage)
      const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

      try {
        const ownerId = this.$route.params.id;
        const apiUrl=API_URLS.getOwnerById(ownerId)
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch owner details');
        }
        
        const data = await response.json();
        this.owner = data;
      } catch (error) {
        //console.error('Error fetching owner details:', error.message);
      }
    },

  },
};
</script>

<style></style>
