<template>
    <div class="content">
      <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-size-66">
          <owners-form data-background-color="'#484848'" :isNewOwner="true"> </owners-form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import OwnersForm from "@/pages/OwnersForm.vue";
  
  export default {
    components: {
        OwnersForm,
    },
  };
  </script>
  