<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-66">
        <car-form data-background-color="'#484848'" :isNewCar="false"> </car-form>
      </div>
    </div>
  </div>
</template>

<script>
import CarForm from "@/pages/CarForm.vue";

export default {
  components: {
    CarForm,
  },
};
</script>
