<template>
  <div id="app">
    <div id="login">
      <div id="left-card">
        <div id="description">
          <h1>MAK AUTO</h1>
          <p>Mak, where passion meets precision, crafting vehicles that embody elegance, performance, and sustainability.</p>
        </div>
      </div>
      <div id="form">
        <form @submit.prevent="login">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="loginData.email" placeholder="Email" autocomplete="off">

          <label for="password">Password</label>&nbsp;
          <i class="fas" :class="[passwordFieldIcon]" @click="hidePassword = !hidePassword"></i>
          <input :type="passwordFieldType" id="password" v-model="loginData.password" placeholder="**********">

          <button type="submit">Log in</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
import axios from 'axios';

export default {
  data() {
    return {
      loginData: {
        email: '',
        password: ''
      },
      hidePassword: true,
    };
  },
  computed: {
    passwordFieldIcon() {
      return this.hidePassword ? "fa-eye" : "fa-eye-slash";
    },
    passwordFieldType() {
      return this.hidePassword ? "password" : "text";
    },
  },
  methods: {
    async login() {
      try {
        const apiUrl = API_URLS.login;
        const response = await axios.post(apiUrl, this.loginData);
        const { accessToken, tokenType, userData } = response.data;

        // Check if the access token exists
        if (!accessToken) {
          throw new Error('Access token not provided');
        }

        // Check if the email and password match
        if (userData.email !== this.loginData.email) {
          throw new Error('Email does not match');
        }
  

        // Assuming that userData does not contain the password for security reasons,
        // hence, we do not check the password match directly. The password check
        // is usually handled by the backend.

        // Store token and user data in local storage
        localStorage.setItem('accessToken', `${tokenType} ${accessToken}`);
        localStorage.setItem('user', JSON.stringify(userData));
        // Show success notification
        this.$swal({
            title: 'Login successful',
            text: 'Welcome to the dashboard!',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          });
        //alert('Login successful:', response.data);

        // Redirect user to dashboard or appropriate page
        this.$router.push('/dashboard');
      } catch (error) {
        // Handle login error, e.g., display an error message to the user
        //alert('Login failed:', error);
        this.$swal({
          title: 'Login failed',
          text: error.message,
          icon: 'error',
        });
      }
    }

  }
}
</script>

<style>
/* Add your styles here */
#app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#login {
  display: flex;
}

#left-card {
  background-color: #2c3e50; /* Anthracite background color */
  color: #ffffff; /* White text color */
  border-radius: 5px 0 0 5px;
  box-shadow: 0px 0px 30px 0px #666;
  padding: 20px;
}

#description {
  text-align: center;
  margin-bottom: 20px;
}

#form {
  background-color: #34495e; /* Anthracite background color */
  color: #ecf0f1; /* Light gray text color */
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 0px 30px 0px #666;
  padding: 20px;
}

#form label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.8em;
  color: #bdc3c7; /* Light gray text color */
}

#form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: #2c3e50; /* Anthracite background color */
  color: #ffffff; /* White text color */
}

#form button {
  width: 100%;
  padding: 10px;
  background-color: #2c3e50; /* Anthracite background color */
  color: #ffffff; /* White text color */
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

#form button:hover {
  background-color: #1a252f; /* Darker anthracite color on hover */
}
</style>
