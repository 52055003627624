<template>
  <div>
    <div class="search-bar">
      <input type="text" placeholder="Search..." v-model="searchQuery">
    </div>
    
    <md-table v-model="currentPageApks" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell v-if="isSaleTable == false">
            <ButtonsApk :row="item"></ButtonsApk>
          </md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="APK Date">{{ item.apk_date }}</md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="APK Valid">{{ item.apk_valid }}</md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="Kilometers">{{ item.km }}</md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="Cost">{{ item.cost }}</md-table-cell>
        <md-table-cell v-if="carType == 'For Service'" style="font-size: 15px" md-label="Customer Price">{{ item.customer_price }}</md-table-cell>
        <md-table-cell v-if="carType == 'For Service'" style="font-size: 15px" md-label="Taken Money">{{ item.taken_money }}</md-table-cell>
        <md-table-cell style="font-size: 15px" md-label="Notes">{{ item.notes }}</md-table-cell>
      </md-table-row>
    </md-table>

    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import ButtonsApk from '@/components/ButtonsApk.vue';
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "ApkTable",
  components: {
    ButtonsApk
    },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
    carType: {
      type: String,
      default: '', // Default value in case it's not provided
    },
    isSaleTable: {
      type: Boolean,
      default: false, // Default value in case it's not provided
    },
  },
  data() {
    return {
      apks: [],
      searchQuery: '',
      currentPage: 1,
      perPage: 10,
      
    };
  },
  computed: {
    filteredApks() {
      return this.apks.filter(apk => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (apk.apk_date && apk.apk_date.toString().includes(this.searchQuery)) ||
               (apk.apk_valid && apk.apk_valid.toLowerCase().includes(searchQueryLower)) ||
               (apk.km && apk.km.toString().includes(this.searchQuery)) ||
               (apk.cost && apk.cost.toString().includes(this.searchQuery)) ||
               (apk.customer_price && apk.customer_price.toString().includes(this.searchQuery)) ||
               (apk.taken_money && apk.taken_money.toString().includes(this.searchQuery)) ||
               (apk.notes && apk.notes.toLowerCase().includes(searchQueryLower));
      });
    },
    totalPages() {
      return Math.ceil(this.filteredApks.length / this.perPage);
    },
    currentPageApks() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredApks.slice(start, end);
    }
  },
  mounted() {
    
    this.fetchApks();
  },
  methods: {

    async fetchApks() {
      const car_id=this.$route.params.id;
      try {
        // Get the JWT token from wherever you have stored it (e.g., localStorage)
        const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
      
        // Check if token exists
        if (!token) {
          //console.error('JWT token not found');
          // Handle the absence of token, such as redirecting to login page or displaying an error message
          return;
        }
        
        const apkUrl=API_URLS.efelistAllApks(car_id)
        const response = await fetch(apkUrl, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
            'Content-Type': 'application/json'
          }
        });
        
        const data = await response.json();
        this.apks = data;
      } catch (error) {
        //console.error("Error fetching APKs:", error);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    }
  }
};
</script>

<style scoped>
/* Add a class to target the table cells */
.table-cell {
  font-size: 80px !important; /* Set the desired font size */
}
.search-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.search-bar input[type="text"] {
  padding: 8px;
  border: 2px solid #484848; /* Changed color to orange */
  border-radius: 4px;
  outline: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: 2px solid #484848; /* Changed color to orange */
  background-color: #484848; /* Changed color to orange */
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #484848; /* Darker shade of orange */
}
</style>
