<template>
  <div>
    <md-table v-model="currentPageUsers" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        
        <md-table-cell md-label="License Plate">{{ item.license_plate }}</md-table-cell>
        <md-table-cell md-label="Make">{{ item.make }}</md-table-cell>
        <md-table-cell md-label="Model">{{ item.model }}</md-table-cell>
        <md-table-cell md-label="Owner">{{ item.owner }}</md-table-cell>
        <md-table-cell md-label="Due Date">{{ item.due_date }}</md-table-cell>
        <md-table-cell md-label="Remaining Days">{{ item.remaining_days }}</md-table-cell>
      </md-table-row>
    </md-table>

    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "OrderedTable",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      users: [],
      currentPage: 1,
      perPage: 2,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.users.length / this.perPage);
    },
    currentPageUsers() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.users.slice(start, end);
    }
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        // Get the JWT token from localStorage
        const token = localStorage.getItem('accessToken');
        const apiUrl=API_URLS.getApkReminders
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        this.users = data;
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: 2px solid #484848; /* Changed color to orange */
  background-color: #484848; /* Changed color to orange */
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #484848; /* Darker shade of orange */
}
</style>
