<template>
    <div>
      <div class="search-bar">
        <input type="text" placeholder="Search..." v-model="searchQuery">
      </div>
      
      <md-table v-model="currentPageCars" :table-header-color="tableHeaderColor">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell>
            <ButtonsCar :row="item"></ButtonsCar>
          </md-table-cell>
          <md-table-cell>  </md-table-cell>
          
          <md-table-cell md-label="License Plate">{{ item.license_plate }}</md-table-cell>
          <md-table-cell md-label="Make">{{ item.make }}</md-table-cell>
          <md-table-cell md-label="Model">{{ item.model }}</md-table-cell>
          <md-table-cell md-label="Owner Name">{{ item.FirstName }}</md-table-cell>
          <md-table-cell md-label="Year">{{ item.car_year }}</md-table-cell>
          <md-table-cell md-label="Package">{{ item.package }}</md-table-cell>
          <md-table-cell md-label="Fuel Type">{{ item.fuel_type }}</md-table-cell>
          <md-table-cell md-label="Gear">{{ item.gear }}</md-table-cell>
          <md-table-cell md-label="Mileage">{{ item.mileage }}</md-table-cell>
          <md-table-cell md-label="Buy Price">{{ item.buy_price }}</md-table-cell>
          <md-table-cell md-label="Buy Date">{{ item.buy_date }}</md-table-cell>
          <md-table-cell md-label="APK Until Valid Date">{{ item.last_apk_date }}</md-table-cell>
          <md-table-cell md-label="Declared Price">{{ item.declared_price }}</md-table-cell>
          <md-table-cell md-label="Car Type">{{ item.car_type }}</md-table-cell>
          <md-table-cell md-label="Status">{{ item.car_status }}</md-table-cell>
        </md-table-row>
      </md-table>
  
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import ButtonsCar from '@/components/ButtonsCar.vue';
  import { API_URLS } from '@/apiConfig.js';
  
  export default {
    name: "CarTable",
    components: {
        ButtonsCar
    },
    props: {
      tableHeaderColor: {
        type: String,
        default: "",
      },
      tableType: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        cars: [],
        searchQuery: '',
        currentPage: 1,
        perPage: 10,
        
      };
    },
    computed: {
        filteredCars() {
  return this.cars.filter(car => {
    const searchQueryLower = this.searchQuery.toLowerCase();
    return (car.FirstName && car.FirstName.toLowerCase().includes(searchQueryLower)) ||
           (car.license_plate && car.license_plate.toLowerCase().includes(searchQueryLower)) ||
           (car.make && car.make.toLowerCase().includes(searchQueryLower)) ||
           (car.model && car.model.toLowerCase().includes(searchQueryLower)) ||
           (car.car_year && car.car_year.toString().includes(this.searchQuery)) ||
           (car.package && car.package.toLowerCase().includes(searchQueryLower)) ||
           (car.fuel_type && car.fuel_type.toLowerCase().includes(searchQueryLower)) ||
           (car.gear && car.gear.toLowerCase().includes(searchQueryLower)) ||
           (car.mileage && car.mileage.toString().includes(this.searchQuery)) ||
           (car.buy_price && car.buy_price.toString().includes(this.searchQuery)) ||
           (car.buy_date && car.buy_date.toString().includes(this.searchQuery)) ||
           (car.last_apk_date && car.last_apk_date.toString().includes(this.searchQuery)) ||
           (car.declared_price && car.declared_price.toString().includes(this.searchQuery)) ||
           (car.car_type && car.car_type.toLowerCase().includes(searchQueryLower)) ||
           (car.car_status && car.car_status.toLowerCase().includes(searchQueryLower));
  });
},
      totalPages() {
        return Math.ceil(this.filteredCars.length / this.perPage);
      },
      currentPageCars() {
        const start = (this.currentPage - 1) * this.perPage;
        const end = start + this.perPage;
        return this.filteredCars.slice(start, end);
      }
    },
    mounted() {
      this.fetchCars();
    },
    methods: {
      async fetchCars() {
        // Get the JWT token from wherever you have stored it (e.g., localStorage)
        const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
        //console.log(token)
        const apiUrl=API_URLS.efelistAllCars
        try {
          const response = await fetch(apiUrl, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            throw new Error('Failed to fetch cars');
          }

          const data = await response.json();
          //console.log(this.tableType)
          if (this.tableType === "tableForSale") {
            this.cars = data.filter(car => car.car_type === "For Sale" && car.car_status === null);
          } else if (this.tableType === "tableForService") {
              this.cars = data.filter(car => car.car_type === "For Service");
          }
          else if (this.tableType === "tableSaledCars") {
            this.cars = data.filter(car => car.car_status && car.car_status.toLowerCase() === "saled");
          }else {
              this.cars = data;
          }
          //console.log(data);
        } catch (error) {
          //console.error("Error fetching cars:", error);
        }
      },

      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToPage(pageNumber) {
        if (pageNumber >= 1 && pageNumber <= this.totalPages) {
          this.currentPage = pageNumber;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  
  .search-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    
  }
  
  .search-bar input[type="text"] {
  padding: 8px;
  border: 2px solid #484848;
  border-radius: 4px;
  outline: none;
}
  .pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: 2px solid #484848;
  background-color: #484848;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #484848;
}
  </style>
  