<template>
  <div>
    <form >
      <md-card>
        <md-card-header :data-background-color="dataBackgroundColor">
          <h4 class="title">Sale Information</h4>
          <p class="category">Manage Sale Information</p>
        </md-card-header>
        <div class="empty-row"></div>
        <md-card-content>
          <div class="md-layout">
            <!-- Sale Date -->
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label class="always-above">Sale Date</label>
                <md-input v-model="sale_date" type="date"></md-input>
              </md-field>
            </div>
            <div class="empty-row"></div>
            <!-- Sale Price -->
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Sale Price</label>
                <md-input v-model="sale_price" type="number" step="any"></md-input>
              </md-field>
            </div>
            <div class="empty-row"></div>
            <!-- Taken Money -->
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Taken Money</label>
                <md-input v-model="taken_money" type="number" step="any"></md-input>
              </md-field>
            </div>
            <div class="empty-row"></div>
            <!-- Notes -->
            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>Notes</label>
                <md-textarea v-model="notes" rows="3"></md-textarea>
              </md-field>
            </div>
            <!-- Submit Button -->
            <div class="md-layout-item md-size-100 text-right">
              <md-button type="submit" class="md-raised md-success add-button" @click="addSale">Sale</md-button>
              <md-button type="submit" class="md-raised md-success add-button" @click="updateSale">Update</md-button>
              <md-button type="button" class="md-raised md-success add-button" @click="removeSale">UnSale</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "SaleForm",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "#484848",
    },

  },


  data() {
    return {
      sale_date: null,
      sale_price: null,
      taken_money: null,
      notes: null,
    };
  },
   created() {
    this.fetchSaleDetails()
  },
  methods: {
    async fetchSaleDetails() {
      try {
        const car_id = this.$route.params.id;
        const token = localStorage.getItem('accessToken');

        // Fetch sale details for the car
        let apiUrl=API_URLS.listAllSales(car_id)
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch sale details');
        }

        const data = await response.json();
        
        // Handle fetched sale details as needed
        //console.log('Sale details:', data);

        // Set sale data to variables
        if (data.length > 0) {
          this.sale_date = data[0].sale_date;
          this.sale_price = data[0].sale_price;
          this.taken_money = data[0].taken_money;
          this.notes = data[0].notes;
        }

   /*       // Debug: Log the values of the variables
        console.log('sale_date:', data[0].sale_date);
        console.log('sale_price:', data[0].sale_price);
        console.log('taken_money:', data[0].taken_money);
        console.log('notes:', data[0].notes); */

      } catch (error) {
        //console.error('Error fetching sale details:', error.message);
        // Handle error as needed
      }
    },

    async addSale() {
      // Check if sale_date and sale_price are filled
      if (!this.sale_date || !this.sale_price) {
        this.$swal({
          title: 'Error',
          text: 'Please fill in the Sale Date and Sale Price fields.',
          icon: 'error',
          timer: 2000, // Dialog will close after 2 seconds
          showConfirmButton: false // Hide the confirm button
        });
        return;
      }

      try {
        const car_id = this.$route.params.id;
        const token = localStorage.getItem('accessToken');

        // Check if the car is already sold
        let apiUrl=API_URLS.efeCarHasSaleInfo(car_id)
        const responseCheck = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        
        if (!responseCheck.ok) {
          throw new Error('Failed to check sale information');
        }

        const dataCheck = await responseCheck.json();

      // Check if the car already has sale info
      if (dataCheck.has_sale_info) {
        this.$swal({
          title: 'Error',
          text: 'This car is already sold.',
          icon: 'error',
          timer: 2000, // Dialog will close after 2 seconds
          showConfirmButton: false // Hide the confirm button
        });
        return;
      }

        //console.log("passed")
      /*   console.log( JSON.stringify({
            sale_date: this.sale_date,
            sale_price: this.sale_price,
            taken_money: this.taken_money,
            notes: this.notes,
          })) */
        // Proceed with creating a new sale
         apiUrl=API_URLS.efeCreateNewSale(car_id)
        const responseCreate = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sale_date: this.sale_date,
            sale_price: this.sale_price,
            taken_money: this.taken_money,
            notes: this.notes,
          }),
        });

        if (!responseCreate.ok) {
          throw new Error('Failed to add sale information');
        }


          this.$swal({
        title: 'Success',
        text: 'Saled successfully!',
        icon: 'success',
        timer: 1000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
        //window.location.reload();
      } catch (error) {
        //console.error("Error adding sale information:", error);
        // Handle error (e.g., show error message to user)
      }
    },
    async updateSale() {
      // Check if sale_date and sale_price are filled
      if (!this.sale_date || !this.sale_price) {
        this.$swal({
          title: 'Error',
          text: 'Please fill in the Sale Date and Sale Price fields.',
          icon: 'error',
          timer: 2000, // Dialog will close after 2 seconds
          showConfirmButton: false // Hide the confirm button
        });
        return;
      }
          try {
        const car_id = this.$route.params.id;
        const token = localStorage.getItem('accessToken');

        // Check if the car is already sold
        let apiUrl=API_URLS.efeCarHasSaleInfo(car_id)
        const responseCheck = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        
        if (!responseCheck.ok) {
          throw new Error('Failed to check sale information');
        }

        const dataCheck = await responseCheck.json();

      // Check if the car already has sale info
      if (!dataCheck.has_sale_info) {
        this.$swal({
          title: 'Error',
          text: 'This car is not sold.',
          icon: 'error',
          timer: 2000, // Dialog will close after 2 seconds
          showConfirmButton: false // Hide the confirm button
        });
        return;
      }
        /* console.log("passed")
        console.log( JSON.stringify({
            car_id:car_id,
            sale_date: this.sale_date,
            sale_price: this.sale_price,
            taken_money: this.taken_money,
            notes: this.notes,
          })) */
        // Proceed with creating a new sale
        apiUrl=API_URLS.efeUpdateCarSale(car_id)
        const responseCreate = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            car_id:car_id,
            sale_date: this.sale_date,
            sale_price: this.sale_price,
            taken_money: this.taken_money,
            notes: this.notes,
          }),
        });

        if (!responseCreate.ok) {
          throw new Error('Failed to update sale information');
        }

            this.$swal({
      title: 'Success',
      text: 'Sale updated successfully!',
      icon: 'success',
      timer: 2000, // Dialog will close after 2 seconds
      showConfirmButton: false // Hide the confirm button
    });

        //window.location.reload();
      } catch (error) {
        //console.error("Error updating sale information:", error);
        // Handle error (e.g., show error message to user)
      }
    },
    async removeSale() {
  try {
    const car_id = this.$route.params.id;
    const token = localStorage.getItem('accessToken');

    // Check if the car is already sold
    let apiUrl=API_URLS.efeCarHasSaleInfo(car_id)
    const responseCheck = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!responseCheck.ok) {
      throw new Error('Failed to check sale information');
    }

    const dataCheck = await responseCheck.json();

    // Check if the car does not have sale info
    if (!dataCheck.has_sale_info) {
      this.$swal({
        title: 'Error',
        text: 'This car is not sold.',
        icon: 'error',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      return;
    }

    // Proceed with removing the sale
    apiUrl=API_URLS.efeDeleteSale(car_id)
    const responseDelete = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        car_id:car_id,
        sale_date: this.sale_date,
        sale_price: this.sale_price,
        taken_money: this.taken_money,
        notes: this.notes,
      }),
    });

    if (!responseDelete.ok) {
      throw new Error('Failed to remove sale information');
    }

    this.$swal({
      title: 'Success',
      text: 'Sale removed successfully!',
      icon: 'success',
      timer: 2000, // Dialog will close after 2 seconds
      showConfirmButton: false // Hide the confirm button
    });

    // Reset fields to null
    this.sale_date = null;
    this.sale_price = null;
    this.taken_money = null;
    this.notes = null;
    
  } catch (error) {
    //console.error("Error removing sale information:", error);
    // Handle error (e.g., show error message to user)
  }
},

  },
};
</script>

<style scoped>
.always-above {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease; /* Optional: Add transition for smooth movement */
}

/* Target all buttons */
button {
  background-color: #484848; /* antrasit color */
}
/* Override button color with !important */
form .md-button.md-raised.md-success.add-button {
  background-color: #484848 !important; /* antrasit color */
}
.empty-row {
  height: 40px; /* Adjust the height as needed */
}
/* Styles... */
</style>
