<template>
    <div>
      <md-menu class="small-menu">
        <md-button class="md-icon-button small-button" md-menu-trigger>
          <md-icon>more_vert</md-icon>
        </md-button>
  
        <md-menu-content class="small-menu-content">
          <md-menu-item @click="btnEdit">Edit</md-menu-item>
          <md-menu-item @click="btnDelete">Delete</md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
  </template>
  
  <style scoped>
  .small-menu {
    font-size: md-xsmall-size-100px !important; /* Adjust font size */
    width: 4px !important; /* Adjust width */
  }
  
  .small-button .md-icon {
    font-size: md-xsmall-size-100pxpx !important; /* Adjust icon size */
  }
  
  .small-menu-content {
    min-width: 20px !important; /* Adjust minimum width */
  }
  </style>
  
  <script>
  import { API_URLS } from '@/apiConfig.js'; 
  export default {
    name: 'ButtonsApk',
    props: {
      row: Object
    },
    methods: {
      btnEdit() {
        // Navigate to the edit route with the car's ID as a parameter
        const carId = this.$route.params.id;
        //console.log(carId)
        this.$router.push(`/maintenance/${carId}/${this.row.id}/update`);
       // router.push({ name: 'CarDetail', params: { id:  }});
      },
      btnDelete() {
  const id = this.row.id; // Assuming the Maintenance object has an 'id' property

  // Show confirmation dialog with SweetAlert
  this.$swal({
    title: 'Confirmation',
    text: 'Are you sure you want to delete this Maintenance?',
    icon: 'warning',
    showCancelButton: true, // Display the cancel button
    confirmButtonText: 'Delete', // Text for the delete button
    cancelButtonText: 'Cancel', // Text for the cancel button
  }).then((result) => {
    if (result.isConfirmed) {
      // Get the JWT token from wherever you have stored it (e.g., localStorage)
      const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
    
      // Check if token exists
      if (!token) {
        // Handle the absence of token, such as redirecting to login page or displaying an error message
        return;
      }
      
      const apiUrl = API_URLS.efeDeleteMaintenance(id);
      fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete Maintenance');
        }
        // Show success message with SweetAlert
        this.$swal({
          title: 'Success',
          text: 'Maintenance deleted successfully!',
          icon: 'success',
          timer: 2000, // Dialog will close after 2 seconds
          showConfirmButton: false // Hide the confirm button
        });
        // Refresh the page after successful deletion
        location.reload();
      }).catch(error => {
        //console.error('Error deleting Maintenance:', error);
        // Handle the error, such as displaying an error message to the user.
      });
    }
  });
},

   

    }
  }
  </script>
  