<template>
  <form @submit.prevent="handleSubmit">
    <md-card>
      <md-card-header :data-background-color="'#484848'">
        <h4 class="title"> {{ isNewCar ? 'Add New Car' : 'Update Car Details' }}</h4>
        <p class="category">MAK</p>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">

          <div class="md-layout-item md-small-size-100 md-size-50">
            <label>Type</label>
            <div class="select-wrapper">
              <v-select v-model="carDetails.car_type" :options="carTypes" label="Car Type" class="custom-dropdown" ></v-select>
            </div>
          </div>

          <!-- License Plate -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>License Plate</label>
              <md-input v-model="carDetails.license_plate" type="text" :InputLabelProps="{ shrink: true }"></md-input>
            </md-field>
          </div>

          <!-- Owner Dropdown -->
          <div class="blank-row"></div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <label>Owner</label>
            <div class="select-wrapper">
              <v-select v-model="carDetails.owner_id" :options="owners" label="Owner" class="custom-dropdown"></v-select>
            </div>
          </div>


          <!-- Make Dropdown -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <label>Make</label>
            <div class="select-wrapper">
              <v-select v-model="carDetails.make" :options="makes" label="Make" @input="onMakeSelected" class="custom-dropdown"></v-select>
            </div>
          </div>

          
         <!--  <div class="blank-row"></div> -->
         <div class="md-layout-item md-small-size-100 md-size-50">
            <label>Model</label>
            <div class="select-wrapper">
              <v-select v-model="carDetails.model" :options="models" label="Model" class="custom-dropdown"></v-select>
            </div>
          </div>
          

          <!-- Car Year -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <label>Car Year</label>
            <div class="select-wrapper">
              <v-select v-model="carDetails.car_year" :options="carDetails.years" label="Car Year" class="custom-dropdown"></v-select>
            </div>
          </div>


          <!-- Package -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Package</label>
              <md-input v-model="carDetails.package" type="text" :InputLabelProps="{ shrink: true }"></md-input>
            </md-field>
          </div>

          <!-- Fuel Type -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <label>Fuel</label>
            <div class="select-wrapper">
              <v-select v-model="carDetails.fuel_type" :options="fuelTypes" label="Fuel Type" class="custom-dropdown"></v-select>
            </div>
          </div>
          

          <!-- Gear -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <label>Gear</label>
            <div class="select-wrapper">
              <v-select v-model="carDetails.gear" :options="gearTypes" label="Gear Type" class="custom-dropdown"></v-select>
            </div>
          </div>
          

          <!-- Mileage -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Mileage</label>
              <md-input v-model="carDetails.mileage" type="number" :InputLabelProps="{ shrink: true }"></md-input>
            </md-field>
          </div>

          <!-- Last APK Date -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label class="always-above">APK Until Valid Date</label>
              <md-input v-model="carDetails.last_apk_date" type="date"></md-input>
            </md-field>
          </div>

          <!-- Buy Price -->
          <div   v-if="showBuyPriceField" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Buy Price</label>
              <md-input v-model="carDetails.buy_price" type="number" step="any" :InputLabelProps="{ shrink: true }"></md-input>
            </md-field>
          </div>

          <!-- Buy Date -->
          <div v-if="showBuyPriceField" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label for="buyDateInput" class="always-above">Buy Date</label>
              <md-input id="buyDateInput" v-model="carDetails.buy_date" type="date"></md-input>
            </md-field>
          </div>




          <!-- Declared Price -->
          <div v-if="showBuyPriceField" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Declared Price</label>
              <md-input v-model="carDetails.declared_price" type="number" step="any" :InputLabelProps="{ shrink: true }"></md-input>
            </md-field>
          </div>


        

          <!-- Car Status -->
 <!--          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Car Status</label>
              <md-input v-model="carDetails.car_status" type="text" :InputLabelProps="{ shrink: true }" disabled></md-input>
            </md-field>
          </div> -->
        </div>

        <div class="md-layout-item md-size-100 text-right">
          <md-button type="submit" class="md-raised md-success"> {{ isNewCar ? 'Add New Car' : 'Update Car' }}</md-button>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { CardPlugin } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { API_URLS } from '@/apiConfig.js';

export default {
  name: "car-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
    isNewCar: {
      type: Boolean,
      default: true, // Default value in case it's not provided
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      carDetails: {
        owner_id: null,
        license_plate: null,
        make: null,
        model: null,
        car_year: null,
        package: null,
        fuel_type: null,
        gear: null,
        mileage: null,
        buy_price: null,
        buy_date: null,
        last_apk_date: null,
        declared_price: null,
        car_type: null,
        car_status: null,
        years: [],
      },
      // Add a new property for years
      showBuyPriceField: false,
      makes: [],
      models: [],
      owners: [],
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
      fuelTypes: [
      'CNG',
      'Diesel',
      'Electric',
      'Electric/Diesel',
      'Electric/Gasoline',
      'Ethanol',
      'Gasoline',
      'Hydrogen',
      'LPG',
      'Others'
    ],
    carTypes: [
      'For Sale',
      'For Service'
    ],
    gearTypes:[
    'Manual',
    'Automatic',
    'Semi-automatic'
    ]
    };
  },
  watch: {
    // Watch for changes to the car_type value
    'carDetails.car_type'(newValue, oldValue) {
      // Update the showBuyPriceField variable based on the new value of car_type
      this.showBuyPriceField = newValue === 'For Sale';
    },
  },
  async created() {

    this.generateYearList();
    this.fetchMakes();
    this.fetchOwners();
    this.fetchModelsOfMake(this.carDetails.make); // Fetch models based on the initial make

    if (this.isNewCar==false) {
      // If carId is provided, fetch car details for updating
      this.fetchCarDetails();
    } else {
      // If carId is not provided, fetch necessary data for creating a new car
 
    }
    

     
  },
  methods: {

    generateYearList() {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= 1990; year--) {
    years.push(year);
  }
  this.carDetails.years = years;
},

    // Handle form submission
    async handleSubmit() {
      try {
        if (this.isNewCar) {
          // If carId is provided, update existing car
          await this.addCar();
        } else {
          // If carId is not provided, add new car
          await this.updateCar();
        }
      } catch (error) {
        ////console.error('Error:', error.message);
      }
    },
  
    notifyVue(verticalAlign, horizontalAlign) {
      var color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        message:
          "Succesfull",
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
      });
    },

    async fetchCarDetails() {
      try {
        const carId = this.$route.params.id;
        // Get the JWT token from wherever you have stored it (e.g., localStorage)
        const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

        const apiUrl =API_URLS.efeGetCarById(carId)
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch car details');
        }
        
        const data = await response.json();

        // Populate carDetails with fetched data
        this.carDetails = data;
        this.generateYearList();
        this.fetchModelsOfMake(this.carDetails.make); // Fetch models based on the initial make

        // Find the owner in the owners array whose ID matches carDetails.OwnerID
        const owner = this.owners.find(owner => owner.ID === this.carDetails.owner_id);
        if (owner) {
          // Set the v-select model to the found owner object
          this.carDetails.owner_id = owner;
        } else {
          //console.error('Owner not found in owners array');
        }
      } catch (error) {
        //console.error('Error fetching car details:', error.message);
      }
    },

    async fetchMakes() {
      try {
        // Get the JWT token from wherever you have stored it (e.g., localStorage)
        const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
        const apiUrl =API_URLS.uniquemakes
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header if necessary
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch makes data');
        }

        const data = await response.json();
        this.makes = data;
      } catch (error) {
        //console.error("Error fetching makes data:", error);
      }
    },
    async fetchModelsOfMake(make) {
        //console.log(make);
        try {
          // Get the JWT token from wherever you have stored it (e.g., localStorage)
          const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
          const apiUrl =API_URLS.uniquemodelsofmake(make)
          const response = await fetch(apiUrl, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header if necessary
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            throw new Error('Failed to fetch models data');
          }

          const data = await response.json();
          this.models = data;
        } catch (error) {
          //console.error("Error fetching models data:", error);
        }
      },

    async onMakeSelected() {
  //console.log('Make selected:', this.carDetails.make);
  
  this.models = []; // Clear the models dropdown
  await this.fetchModelsOfMake(this.carDetails.make);
},

async fetchOwners() {
  try {
    // Get the JWT token from wherever you have stored it (e.g., localStorage)
    const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
    const apiUrl =API_URLS.efeListAllOwners
    const response = await fetch(apiUrl, {
      headers: {
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header if necessary
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch owners data');
    }

    const data = await response.json();
    // Map the response data to a format suitable for your component
    this.owners = data.map(owner => ({ ID: owner.id, Owner: `${owner.first_name} ${owner.last_name}` }));
    //console.log(this.owners);
  } catch (error) {
    //console.error("Error fetching owners data:", error);
  }
},

  async addCar(carDetails) {

    // Check if owner_id is blank or null
    if (!this.carDetails.owner_id || this.carDetails.owner_id === '') {
      this.$swal({
        title: 'Error',
        text: 'Name cannot be blank',
        icon: 'error',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      return;
    }

    // Check if make is blank or null
    if (!this.carDetails.make || this.carDetails.make.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'Make cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Check if model is blank or null
    if (!this.carDetails.model || this.carDetails.model.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'Model cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Check if license_plate is blank or null
    if (!this.carDetails.license_plate || this.carDetails.license_plate.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'License plate cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Check if car_type is blank or null
    if (!this.carDetails.car_type || this.carDetails.car_type.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'Car type cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Additional checks based on car_type
    if (this.carDetails.car_type === 'For Sale') {
      // If CarType is 'For Sale', ensure buy_price and buy_date are provided
      if (!this.carDetails.buy_price || !this.carDetails.buy_date) {
        this.$swal({
          title: 'Error',
          text: 'Buy Price and Buy Date are required for cars listed for sale',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        return;
      }
    }



    const owner_id = typeof this.carDetails.owner_id === 'object' ? this.carDetails.owner_id.ID : this.carDetails.owner_id;
    
    // Get the JWT token from wherever you have stored it (e.g., localStorage)
    const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

    try {
      const requestBody = {
        license_plate: this.carDetails.license_plate,
        make: this.carDetails.make,
        model: this.carDetails.model,
        car_year: this.carDetails.car_year,
        package: this.carDetails.package,
        fuel_type: this.carDetails.fuel_type,
        gear: this.carDetails.gear,
        mileage: this.carDetails.mileage,
        buy_price: this.carDetails.buy_price,
        buy_date: this.carDetails.buy_date,
        last_apk_date: this.carDetails.last_apk_date,
        declared_price: this.carDetails.declared_price,
        car_type: this.carDetails.car_type,
        car_status: this.carDetails.car_status,
        owner_id: owner_id,
      };

      const apiUrl =API_URLS.addCar
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Include the JWT token in the Authorization header
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to add car");
      }

      // If successful, you can handle the response as needed
      const data = await response.json();
      //console.log("Car added successfully:", data);       
      this.notifyVue('bottom', 'center')
      this.$router.push('/cars'); // Assuming '/cars' is the route to the cars page
      // You can perform any additional actions here, such as showing a success message or navigating to another page.
    } catch (error) {
      //console.error("Error adding car:", error.message);
      // Handle the error, such as displaying an error message to the user.
    }
  },

  async updateCar() {

    // Check if owner_id is blank or null
    if (!this.carDetails.owner_id || this.carDetails.owner_id === '') {
      this.$swal({
        title: 'Error',
        text: 'Name cannot be blank',
        icon: 'error',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      return;
    }

    // Check if make is blank or null
    if (!this.carDetails.make || this.carDetails.make.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'Make cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Check if model is blank or null
    if (!this.carDetails.model || this.carDetails.model.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'Model cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Check if license_plate is blank or null
    if (!this.carDetails.license_plate || this.carDetails.license_plate.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'License plate cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Check if car_type is blank or null
    if (!this.carDetails.car_type || this.carDetails.car_type.trim() === '') {
      this.$swal({
        title: 'Error',
        text: 'Car type cannot be blank',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Additional checks based on car_type
    if (this.carDetails.car_type === 'For Sale') {
      // If CarType is 'For Sale', ensure buy_price and buy_date are provided
      if (!this.carDetails.buy_price || !this.carDetails.buy_date) {
        this.$swal({
          title: 'Error',
          text: 'Buy Price and Buy Date are required for cars listed for sale',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        return;
      }
    }

    
    //console.log('Car updated object:', JSON.stringify(this.carDetails));
    const owner_id = typeof this.carDetails.owner_id === 'object' ? this.carDetails.owner_id.ID : this.carDetails.owner_id;
    const requestBody = {
      license_plate: this.carDetails.license_plate,
      make: this.carDetails.make,
      model: this.carDetails.model,
      car_year: this.carDetails.car_year,
      package: this.carDetails.package,
      fuel_type: this.carDetails.fuel_type,
      gear: this.carDetails.gear,
      mileage: this.carDetails.mileage,
      buy_price: this.carDetails.buy_price,
      buy_date: this.carDetails.buy_date,
      last_apk_date: this.carDetails.last_apk_date,
      declared_price: this.carDetails.declared_price,
      car_type: this.carDetails.car_type,
      car_status: this.carDetails.car_status,
      owner_id: owner_id,
    };

    // Get the JWT token from wherever you have stored it (e.g., localStorage)
    const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

    try {
      const carId = this.$route.params.id;
      const apiUrl =API_URLS.efeUpdateCar(carId)
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to update car');
      }

      // If successful, you can handle the response as needed
      const data = await response.json();
      //console.log('Car updated successfully:', data);
      this.notifyVue('bottom', 'center');
      this.$router.push('/cars'); // Assuming '/cars' is the route to the cars page
      // You can perform any additional actions here, such as showing a success message or navigating to another page.
    } catch (error) {
      //console.error('Error updating car:', error.message);
      // Handle the error, such as displaying an error message to the user.
    }
  },

  },
};
</script>

<style>
.always-above {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease; /* Optional: Add transition for smooth movement */
}
/* Adjust input field position to accommodate the label */
md-input {
  padding-top: 20px; /* Adjust according to label height */
}

/* Optional: Adjust input field style for better alignment */
md-input {
  width: 100%;
}

.custom-dropdown {
  width: 100%; /* Set the width to 100% or adjust as needed */
}
/* Custom style for the blank row */
.blank-row {
  height: 60px; /* Set the height of the blank row as needed */
}
/* Target all buttons */
button {
  background-color: #484848; /* antrasit color */
}
/* Override button color with !important */
form .md-button.md-raised.md-success {
  background-color: #484848 !important; /* antrasit color */
}
</style>
