<template>
  <div class="content">
    <div class="md-layout">
      <!-- Stats Card for Total Car -->
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="#484848">
          <template slot="header">
            <md-icon>check</md-icon>
          </template>
          <template slot="content">
            <p class="category" style="font-size: 18px;">Total Car</p> <!-- Increased font size to 18px -->
            <!-- Bind the total number of cars here -->
            <h3 class="title" style="font-size: 24px;">{{ totalCars }}</h3> <!-- Increased font size to 24px -->
          </template>
        </stats-card>
      </div>

      <!-- Stats Card for For Sale -->
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="#484848">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>
          <template slot="content">
            <p class="category" style="font-size: 18px;">For Sale</p>
            <!-- Bind the number of cars for sale here -->
            <h3 class="title" style="font-size: 24px;">{{ forSaleCars }}</h3>
          </template>
        </stats-card>
      </div>

      <!-- Stats Card for For Service -->
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="#484848">
          <template slot="header">
            <md-icon>build</md-icon>
          </template>
          <template slot="content">
            <p class="category" style="font-size: 18px;">For Service</p>
            <!-- Bind the number of cars for service here -->
            <h3 class="title" style="font-size: 24px;">{{ forServiceCars }}</h3>
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Car Apks Reminder</h4>
            <p class="category">MAK</p>
          </md-card-header>
          <md-card-content>
            <ordered-table table-header-color="'#484848'"></ordered-table>
          </md-card-content>
        </md-card>
      </div>


      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <nav-tabs-card >
          <template slot="content" >
            <span class="md-nav-tabs-title"  >Tasks:</span>
            <md-tabs class="md-success" md-alignment="left" style="background-color: #484848" >
              <md-tab id="tab-home" md-label="Kaya KINAY" md-icon="airplay">
                <nav-tabs-table whichCategory="Category A"></nav-tabs-table>
              </md-tab>

              <md-tab id="tab-pages" md-label="Mustafa TOSUN" md-icon="movie">
                <nav-tabs-table whichCategory="Category B"></nav-tabs-table>
              </md-tab>
<!-- 
              <md-tab id="tab-posts" md-label="server" md-icon="cloud">
                <nav-tabs-table></nav-tabs-table>
              </md-tab> -->
            </md-tabs>
          </template>
        </nav-tabs-card>
      </div>
    </div>


  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
import { StatsCard,NavTabsTable,NavTabsCard,OrderedTable } from "@/components";


export default {
  components: {
    StatsCard,
    NavTabsTable,
    NavTabsCard,
    OrderedTable,
    
  },
  data() {
    return {
      totalCars: 0, // You can replace this with your actual data
      forSaleCars: 0, // Example data, replace with actual value
      forServiceCars: 0, // Example data, replace with actual value
    };
  },
  mounted() {
    // You can call any methods or perform actions on mount if needed
    this.fetchCarCounts();
  },
  methods: {
    async fetchCarCounts() {
        try {
            // Get the JWT token from wherever you have stored it (e.g., localStorage)
            const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
            
            const apiUrl=API_URLS.getCarCounts
            const response = await fetch(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch car counts');
            }

            const data = await response.json();
            this.totalCars = data.total_car_count;
            this.forSaleCars = data.car_count_for_sale;
            this.forServiceCars = data.car_count_for_service;
        } catch (error) {
            ////console.error('Error fetching car counts:', error);
        }
    }
  }
};
</script>

<style scoped>
.nav-tabs-card {
    background-color: #484848 !important;
}
</style>
