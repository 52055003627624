<template>
    <div >
        
    </div>
  </template>
  
  <script>
import axios from 'axios'; // Import Axios here
import { API_URLS } from '@/apiConfig.js';
  export default {
    methods: {
        logout() {
      // Get the access token from localStorage
      const accessToken = localStorage.getItem('accessToken');

      // Check if access token exists
      if (!accessToken) {
        //console.error("Access token not found.");
        this.$router.push('/login');
        return;
      }

      // Set up the headers with the access token
      const headers = {
        Authorization: `Bearer ${accessToken}`
      };

      // Send the logout request
      const apiUrl=API_URLS.logout
      axios.post(apiUrl, null, { headers })
        .then(response => {
          // Clear authentication token and redirect to login page
          localStorage.removeItem('accessToken');
          this.$router.push('/login');
          window.location.reload();
        })
        .catch(error => {
          //console.error('Logout failed:', error);
        });
    },

    },
    created(){
        this.logout();
    }
  };
  </script>
  
  <style scoped>
  .logout-button {
    cursor: pointer;
    /* Add your styling here */
  }
  </style>
  