// apiConfig.js
//const baseURL = 'http://127.0.0.1:8000/api/';
const baseURL = 'https://api.bestautocontrol.com/public/api/';

export const API_URLS = {
  
  //login
  logout:baseURL + 'logout',
  login:baseURL + 'login',

  //cars
  efelistAllCars:baseURL + 'efelistAllCars',
  uniquemakes: baseURL + 'unique-makes',
  efeDeleteCar: id => `${baseURL}efeDeleteCar/${id}`,
  efeUpdateCar : car_id => `${baseURL}efeUpdateCar/${car_id}`,
  efeGetCarById: car_id => `${baseURL}efeGetCarById/${car_id}`,
  uniquemodelsofmake: make => `${baseURL}unique-models-of-make/${make}`,
  addCar:baseURL + 'addCar',
  getApkReminders:baseURL + 'getApkReminders',
  getCarDetailsForSale: car_id => `${baseURL}getCarDetailsForSale/${car_id}`,
  
  //owners
  efeListAllOwners: baseURL + 'efeListAllOwners',
  addOwner: baseURL + 'efeAddOwner',
  updateOwner: id => `${baseURL}efeUpdateOwner/${id}`,
  efeDeleteOwner: id => `${baseURL}efeDeleteOwner/${id}`,
  getOwnerById: id => `${baseURL}efeGetOwnerById/${id}`,

  //apk
  efeDeleteApk: id => `${baseURL}efeDeleteApk/${id}`,
  getApkByApkID: apk_id => `${baseURL}getApkByApkID/${apk_id}`,
  efeCreateNewApk: car_id => `${baseURL}efeCreateNewApk/${car_id}`,
  efeUpdateCarApk: apk_id => `${baseURL}efeUpdateCarApk/${apk_id}`,
  efelistAllApks: carId => `${baseURL}efelistAllApks/${carId}/apks`,
 
  //maintenance
  efeDeleteMaintenance: id => `${baseURL}efeDeleteMaintenance/${id}`,
  getMaintenanceById: m_id => `${baseURL}getMaintenanceById/${m_id}`,
  efeCreateNewMaintenance: car_id => `${baseURL}efeCreateNewMaintenance/${car_id}`,
  efeUpdateCarMaintenance: m_id => `${baseURL}efeUpdateCarMaintenance/${m_id}`,
  efelistAllMaintenances: carId => `${baseURL}efelistAllMaintenances/${carId}/maintenances`,


  //extra cost
  efeDeleteExtraCost: id => `${baseURL}efeDeleteExtraCost/${id}`,
  getExtraCostByExtraCostID: e_id => `${baseURL}getExtraCostByExtraCostID/${e_id}`,
  efeCreateNewExtraCost: car_id => `${baseURL}efeCreateNewExtraCost/${car_id}`,
  efeUpdateCarExtraCost: e_id => `${baseURL}efeUpdateCarExtraCost/${e_id}`,
  efelistAllExtraCosts: carId => `${baseURL}efelistAllExtraCosts/${carId}/extra-costs`,
 

  //company cost
  deletecompanycosts: id =>  `${baseURL}delete-company-costs/${id}`, 
  companycosts: baseURL + 'company-costs',
  addcompanycosts: baseURL + 'add-company-costs',

  //sale
  listAllSales: carId => `${baseURL}efelistAllSales/${carId}/sales`,
  efeCarHasSaleInfo: car_id => `${baseURL}efeCarHasSaleInfo/${car_id}`,
  efeCreateNewSale: car_id => `${baseURL}efeCreateNewSale/${car_id}`,
  efeUpdateCarSale: car_id => `${baseURL}efeUpdateCarSale/${car_id}`,
  efeDeleteSale: car_id => `${baseURL}efeDeleteSale/${car_id}`,

  //tasks
  tasks: baseURL + 'tasks',
  tasksByCategory: baseURL + 'tasksByCategory',
  tasksByCategoryB: baseURL + 'tasksByCategoryB',
  tasksUpdate: id =>  `${baseURL}tasks/${id}`, 
  tasksDelete: id =>  `${baseURL}tasks/${id}`, 

  //dashboard
  getCarCounts: baseURL + 'getCarCounts',
};