<template>
    <div>
      <md-menu class="small-menu">
        <md-button class="md-icon-button small-button" md-menu-trigger>
          <md-icon>more_vert</md-icon>
        </md-button>
  
        <md-menu-content class="small-menu-content">
          <md-menu-item @click="btnEdit">Edit</md-menu-item>
          <md-menu-item @click="btnDelete">Delete</md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
  </template>
  
  <style scoped>
  .small-menu {
    font-size: md-xsmall-size-100px !important; /* Adjust font size */
    width: 4px !important; /* Adjust width */
  }
  
  .small-button .md-icon {
    font-size: md-xsmall-size-100pxpx !important; /* Adjust icon size */
  }
  
  .small-menu-content {
    min-width: 20px !important; /* Adjust minimum width */
  }
  </style>
  
  <script>
  import { API_URLS } from '@/apiConfig.js';

  export default {
    name: 'ButtonsOwner',
    props: {
      row: Object
    },
    methods: {
      btnEdit() {
        //console.log(this.row.id)
        this.$router.push(`/owners/${this.row.id}`);
      },
      async btnDelete() {
  const ID = this.row.id;

  // Show confirmation dialog before proceeding with deletion
  const confirmDelete = await this.$swal({
    title: 'Confirmation',
    text: 'Are you sure you want to delete this Owner? This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel',
  });

  if (!confirmDelete.isConfirmed) {
    return; // If user cancels deletion, exit method
  }

  // Get the JWT token from wherever you have stored it (e.g., localStorage)
  const token = localStorage.getItem('accessToken');

  // Check if token exists
  if (!token) {
    // Handle the absence of token, such as redirecting to login page or displaying an error message
    return;
  }

  try {
    const apiUrl = API_URLS.efeDeleteOwner(ID);
    const response = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to delete owner');
    }

    const responseData = await response.json();

    // Check if the response contains a message indicating that the owner cannot be deleted
    if (responseData.message && responseData.message.includes('Cannot delete owner')) {
      // Show an error message to the user indicating that the owner cannot be deleted
      await this.$swal({
        title: 'Error',
        text: 'Owner has cars, first remove this owner cars',
        icon: 'error'
      });
      return; // Exit the method
    }

    // Show success message with SweetAlert
    await this.$swal({
      title: 'Success',
      text: `Owner  deleted successfully!`,
      icon: 'success',
      timer: 2000, // Dialog will close after 2 seconds
      showConfirmButton: false // Hide the confirm button
    });

    // After successful deletion, you might want to refetch the data to update the table
    window.location.reload();
  } catch (error) {
    // Handle error (e.g., show error message to user)
    //console.error("Error:", error);
  }
}


    }
  }
  </script>
  