<template>
  <div>
    <div class="search-bar">
      <input type="text" placeholder="Search..." v-model="searchQuery">
    </div>
    
    <md-table v-model="currentPageOwners" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell>
            <ButtonsOwner :row="item" ></ButtonsOwner>
          </md-table-cell>
        <md-table-cell md-label="First Name">{{ item.first_name }}</md-table-cell>
        <md-table-cell md-label="Last Name">{{ item.last_name }}</md-table-cell>
        <md-table-cell md-label="Phone">{{ item.phone }}</md-table-cell>
        <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
      </md-table-row>
    </md-table>

    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
import ButtonsOwner from '@/components/ButtonsOwner.vue';
export default {
  name: "OwnerTable",
  components: {
        ButtonsOwner
    },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      owners: [],
      searchQuery: '',
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    filteredOwners() {
      return this.owners.filter(owner => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (owner.first_name && owner.first_name.toLowerCase().includes(searchQueryLower)) ||
               (owner.last_name && owner.last_name.toLowerCase().includes(searchQueryLower)) ||
               (owner.phone && owner.phone.includes(this.searchQuery)) ||
               (owner.email && owner.email.toLowerCase().includes(searchQueryLower));
      });
    },
    totalPages() {
      return Math.ceil(this.filteredOwners.length / this.perPage);
    },
    currentPageOwners() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredOwners.slice(start, end);
    }
  },
  mounted() {
    this.fetchOwners();
  },
  methods: {
    async fetchOwners() {
      // Get the JWT token from wherever you have stored it (e.g., localStorage)
      const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

      try {
        const apiUrl=API_URLS.efeListAllOwners
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch owners data');
        }

        const data = await response.json();
        this.owners = data;
      } catch (error) {
        //console.error("Error fetching owners:", error);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    }
  }
};
</script>

<style scoped>
.search-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.search-bar input[type="text"] {
  padding: 8px;
  border: 2px solid #484848; /* Changed color to blue */
  border-radius: 4px;
  outline: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: 2px solid #484848; /* Changed color to blue */
  background-color: #484848; /* Changed color to blue */
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #484848; /* Darker shade of blue */
}
</style>

