// =========================================================
// * Vue Material Dashboard - v1.5.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2024 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";

// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";

import SidebarPlugin from './components/SidebarPlugin';
// Register SideBar component globally
import SideBar from './components/SidebarPlugin/SideBar.vue';
Vue.component('side-bar', SideBar);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});

Vue.prototype.$Chartist = Chartist;

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);

Vue.use(SidebarPlugin);

Vue.use(VueSweetalert2);
/* // Add the router navigation guard
router.beforeEach((to, from, next) => {
  const isLoginPage = to.path === '/login';

  // Get a reference to the sidebar instance
  const sidebar = router.app.$refs.sidebar;
  console.log('isLoginPage:', isLoginPage);
  console.log('sidebar:', sidebar);
  if (isLoginPage && sidebar) {
    console.log('login page');
    // If the user is on the login page and sidebar is available, hide the sidebar
    sidebar.displaySidebar(false);
  } else if (sidebar) {
    // For other pages and if sidebar is available, show the sidebar
    sidebar.displaySidebar(true);
  }

  next(); // Proceed with the navigation
}); */

// Navigation guard to check authentication
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.meta.requiresAuth) {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem('accessToken') !== null;
    if (isAuthenticated) {
      // If authenticated, allow navigation
      next();
    } else {
      // If not authenticated, redirect to login page
      next('/login');
    }
  } else {
    // If the route does not require authentication, allow navigation
    next();
  }
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  data: {
    Chartist: Chartist,
  },
});
