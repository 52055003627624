<template>
  <div>
    <!-- Search bar -->
    <div class="search-bar">
      <input type="text" placeholder="Search..." v-model="searchQuery">
    </div>

    <!-- Maintenance table -->
    <md-table v-model="currentPageMaintenances">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <!-- Buttons for maintenance (if any) -->
        <md-table-cell v-if="isSaleTable == false">
            <ButtonsMaintenance :row="item"></ButtonsMaintenance>
          </md-table-cell>
        <!-- Maintenance date -->
        <md-table-cell md-label="Maintenance Date">{{ item.maintenance_date }}</md-table-cell>
        <!-- Kilometers -->
        <md-table-cell md-label="Kilometers">{{ item.km }}</md-table-cell>
        <!-- Cost -->
        <md-table-cell md-label="Cost">{{ item.cost }}</md-table-cell>
        <!-- Customer Price -->
        <md-table-cell  v-if="carType == 'For Service'" md-label="Customer Price">{{ item.customer_price }}</md-table-cell>
        <!-- Taken Money -->
        <md-table-cell   v-if="carType == 'For Service'" md-label="Taken Money">{{ item.taken_money }}</md-table-cell>
        <!-- Notes -->
        <md-table-cell md-label="Notes">{{ item.notes }}</md-table-cell>
      </md-table-row>
    </md-table>

    <!-- Pagination -->
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
import ButtonsMaintenance from '@/components/ButtonsMaintenance.vue';
export default {
  name: "MaintenanceTable",
  components: {
    ButtonsMaintenance
  },
  props: {
    carType: {
      type: String,
      default: '', // Default value in case it's not provided
    },
    isSaleTable: {
      type: Boolean,
      default: false, // Default value in case it's not provided
    },
  },
  data() {
    return {
      maintenances: [],
      searchQuery: '',
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    filteredMaintenances() {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.maintenances.filter(maintenance => {
        return (maintenance.maintenance_date && maintenance.maintenance_date.toString().includes(this.searchQuery)) ||
               (maintenance.km && maintenance.km.toString().includes(this.searchQuery)) ||
               (maintenance.cost && maintenance.cost.toString().includes(this.searchQuery)) ||
               (maintenance.customer_price && maintenance.customer_price.toString().includes(this.searchQuery)) ||
               (maintenance.taken_money && maintenance.taken_money.toString().includes(this.searchQuery)) ||
               (maintenance.notes && maintenance.notes.toLowerCase().includes(searchQueryLower));
      });
    },
    totalPages() {
      return Math.ceil(this.filteredMaintenances.length / this.perPage);
    },
    currentPageMaintenances() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredMaintenances.slice(start, end);
    }
  },
  mounted() {
    this.fetchMaintenances();
  },
  methods: {
    async fetchMaintenances() {
      const car_id = this.$route.params.id;
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          //console.error('JWT token not found');
          return;
        }
        const apiUrl=API_URLS.efelistAllMaintenances(car_id)
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        this.maintenances = data;
      } catch (error) {
        //console.error("Error fetching maintenances:", error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    }
  }
};
</script>

<style scoped>
.search-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.search-bar input[type="text"] {
  padding: 8px;
  border: 2px solid #484848;
  border-radius: 4px;
  outline: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: 2px solid #484848;
  background-color: #484848;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #484848;
}
</style>
