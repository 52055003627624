<template>
  <div>
    <div class="search-bar">
      <!-- Your search input field goes here -->
      <input type="text" placeholder="Search..." v-model="searchQuery">
    </div>
    
    <md-table v-model="currentPageUsers" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <!-- Replace md-icon with Buttons component -->
        <md-table-cell>
          <ButtonsOwner :row="item"></ButtonsOwner>
        </md-table-cell>
        <md-table-cell md-label="First Name">{{ item.FirstName }}</md-table-cell>
        <md-table-cell md-label="Last Name">{{ item.LastName }}</md-table-cell>
        <md-table-cell md-label="Phone">{{ item.Phone }}</md-table-cell>
        <md-table-cell md-label="Email">{{ item.Email }}</md-table-cell>
      </md-table-row>
    </md-table>

    <!-- Pagination controls -->
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
// Import Buttons component
import ButtonsOwner from '@/components/ButtonsOwner.vue';
import { API_URLS } from '@/apiConfig.js';

export default {
  name: "SimpleTable",
  components: {
    ButtonsOwner // Register Buttons component
  },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      users: [],
      searchQuery: '', // Initialize the search query
      currentPage: 1,
      perPage: 2, // Number of items per page
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user => {
        // Filter based on searchQuery
        return user.FirstName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
               user.LastName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
               user.Phone.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
               user.Email.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.perPage);
    },
    currentPageUsers() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredUsers.slice(start, end);
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const apiUrl=API_URLS.efeListAllOwners
        const response = await fetch(apiUrl);
        const data = await response.json();
        this.users = data; // Assuming the API response is an array of objects
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    }
  }
};
</script>

<style scoped>
.search-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px; /* Adjust as needed */
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: 2px solid #4CAF50;
  background-color: #4CAF50;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #45a049;
}

</style>
