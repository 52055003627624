<template>
    <div>
      <md-menu class="small-menu">
        <md-button class="md-icon-button small-button" md-menu-trigger>
          <md-icon>more_vert</md-icon>
        </md-button>
  
        <md-menu-content class="small-menu-content">
          <md-menu-item @click="btnEdit">Edit</md-menu-item>
          <md-menu-item @click="btnDelete">Delete</md-menu-item>
          <md-menu-item @click="btnAPK">APK</md-menu-item>
          <md-menu-item @click="btnMaintenance">Maintenance</md-menu-item>
          <md-menu-item @click="btnExtraCost">Extra Cost</md-menu-item>
          <md-menu-item @click="btnSale">Sale</md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
  </template>
  
  <style scoped>
  .small-menu {
    font-size: md-xsmall-size-100px !important; /* Adjust font size */
    width: 4px !important; /* Adjust width */
  }
  
  .small-button .md-icon {
    font-size: md-xsmall-size-100pxpx !important; /* Adjust icon size */
  }
  
  .small-menu-content {
    min-width: 20px !important; /* Adjust minimum width */
  }
  </style>
  
  <script>
  import { API_URLS } from '@/apiConfig.js';

  
  export default {
    name: 'ButtonsCar',
    props: {
      row: Object
    },

    methods: {

      btnEdit() {
        // Navigate to the edit route with the car's ID as a parameter
        //console.log(this.row.id)
        this.$router.push(`/cars/${this.row.id}`);
       // router.push({ name: 'CarDetail', params: { id:  }});
      },
      async btnDelete() {
  const ID = this.row.id;

  // Show confirmation dialog with SweetAlert
  const confirmDelete = await this.$swal({
    title: 'Confirmation',
    text: 'Are you sure you want to delete this car? This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true, // Display the cancel button
    confirmButtonText: 'Delete', // Text for the delete button
    cancelButtonText: 'Cancel', // Text for the cancel button
  });

  if (!confirmDelete.isConfirmed) {
    return; // If user cancels deletion, exit method
  }

  // Get the JWT token from wherever you have stored it (e.g., localStorage)
  const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

  // Check if token exists
  if (!token) {
    // Handle the absence of token, such as redirecting to login page or displaying an error message
    return;
  }

  const apiUrl = API_URLS.efeDeleteCar(ID);
  try {
    const response = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to delete car');
    }

    // Show success message with SweetAlert
    await this.$swal({
      title: 'Success',
      text: `Car deleted successfully!`,
      icon: 'success',
      timer: 2000, // Dialog will close after 2 seconds
      showConfirmButton: false // Hide the confirm button
    });

    // Refresh the page after successful deletion
    window.location.reload();
  } catch (error) {
    // Handle the error, such as displaying an error message to the user.
    //console.error('Error:', error);
  }
},

      btnAPK(){
        this.$router.push(`/apk/${this.row.id}`);
      },
      btnMaintenance(){
        this.$router.push(`/maintenance/${this.row.id}`);
      },
      btnExtraCost(){
        this.$router.push(`/extracost/${this.row.id}`);
      },
      btnSale(){
        if(this.row.car_type=="For Service"){
          alert("This car is For Service can not be saled!")
        }
        else{
          this.$router.push(`/sale/${this.row.id}`);
        }
        
      }
    }
  }
  </script>
  