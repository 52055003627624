<template>
  <div>
    <md-table v-model="tasks" @md-selected="onSelect">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        md-auto-select
      >
        <!-- Checkbox -->
        <md-table-cell>
          <md-checkbox v-model="item.done"></md-checkbox>
        </md-table-cell>
        <md-table-cell >
          <!-- Editable task_name -->
          <md-field>
            <label>Edit Task Name</label>
            <md-input v-model="item.task_name" @input="updateTaskName(item)"  ></md-input>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <md-button class="md-just-icon md-simple md-primary" @click="editItem(item)">
            <md-icon>save</md-icon>
            <md-tooltip md-direction="top">Save</md-tooltip>
          </md-button>
          <md-button class="md-just-icon md-simple md-danger" @click="deleteItem(item)">
            <md-icon>close</md-icon>
            <md-tooltip md-direction="top">Delete</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-button class="md-just-icon md-simple md-danger" @click="addNewTask">
      <md-icon>add</md-icon>
      <md-tooltip md-direction="top">Add New Task</md-tooltip>
    </md-button>
    <md-field>
      <label>New Task Name</label>
      <md-input v-model="newTaskName"></md-input>
    </md-field>
  </div>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
import axios from 'axios';

export default {
  name: "NavTabsTable",
  props: {
    whichCategory: String // Define whichCategory prop
  },
  data() {
    return {
      selected: [],
      tasks: [],
      newTaskName: '' 
    };
  },
  mounted() {
    this.fetchTasks();
  },
  methods: {
    addNewTask() {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        ////console.error('JWT token not found');
        return;
      }

      // Make sure newTaskName is not empty
      if (!this.newTaskName.trim()) {
        //console.error('Task name cannot be empty');
        return;
      }

      const newTask = {
        category:this.whichCategory,
        task_name: this.newTaskName,
        done: false // Set done to false for a new task
      };
      //console.log(newTask)
      const apiUrl=API_URLS.tasks
      axios.post(apiUrl, newTask, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          //console.log('Task added successfully:', response.data);
          // Reload tasks after successful addition
          this.fetchTasks();
          // Clear newTaskName for the next task
          this.newTaskName = '';
          alert("Task added successfully");
        })
        .catch(error => {
          //console.error('Error adding task:', error);
        });
    },
    updateTaskName(item) {
    // Implement logic to update task_name
    //console.log('Updated task name:', item.task_name);
  },
    async fetchTasks() {
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          //console.error('JWT token not found');
          return;
        }
        
        let url; // Declare url variable here

        //console.log(this.whichCategory)
        
        if (this.whichCategory === "Category A") {
          url = API_URLS.tasksByCategory;
        } else {
          url = API_URLS.tasksByCategoryB;
        }
        
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        //console.log(data);
        
        // Ensure 'done' property is boolean
        this.tasks = data.map(task => {
          return { ...task, done: Boolean(task.done) };
        });
        if (this.tasks.length > 0) {
          //console.log(this.tasks[0].task_name);
          //console.log(this.tasks[0].done);
        } else {
          ////console.error('No tasks fetched or tasks array is empty');
        }
        
        //console.log(this.tasks);
        
      } catch (error) {
        //console.error('Error fetching tasks:', error);
      }
  },


editItem(item) {
  // Implement edit functionality
  //console.log('Edit:', item);

  const token = localStorage.getItem('accessToken');
  if (!token) {
    //console.error('JWT token not found');
    return;
  }

  const apiUrl=API_URLS.tasksUpdate(item.id)
  axios.patch(apiUrl, item, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      //console.log('Task updated successfully:', response.data);
      // Reload tasks after successful update
      this.fetchTasks();
      alert("saved")
    })
    .catch(error => {
      //console.error('Error updating task:', error);
    });
},

deleteItem(item) {
  // Confirm with the user before deleting the task
  if (!confirm(`Are you sure you want to delete the task "${item.task_name}"?`)) {
    return; // Do nothing if the user cancels
  }

  const token = localStorage.getItem('accessToken');
  if (!token) {
    //console.error('JWT token not found');
    return;
  }
  const apiUrl=API_URLS.tasksDelete(item.id)
    axios.delete(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        //console.log('Task deleted successfully:', response.data);
        // Remove the deleted task from the tasks list
        this.tasks = this.tasks.filter(task => task.id !== item.id);
        alert("Task deleted successfully");
      })
      .catch(error => {
        //console.error('Error deleting task:', error);
      });
  },

    onSelect(items) {
      this.selected = items;
    }
  }
};
</script>
