import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import LoginLayout from "@/pages/Layout/LoginLayout.vue"; // Import the new login layout component
import Dashboard from "@/pages/Dashboard.vue";
/* import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue"; */
import OwnerAdd from "@/pages/OwnerAdd.vue";
import Cars from "@/pages/Cars.vue";
import Owners from "@/pages/Owners.vue";
import CompanyCost from "@/pages/CompanyCost.vue";
import CarUpdate from "@/pages/CarUpdate.vue";
import CarAdd from "@/pages/CarAdd.vue";
import OwnerUpdate from "@/pages/OwnerUpdate.vue";
import Login from "@/pages/Login.vue";
import Apk from "@/pages/Apk.vue";
import ApkUpdate from "@/pages/ApkUpdate.vue";  
import Maintenance from "@/pages/Maintenance.vue";
import MaintenanceUpdate from "@/pages/MaintenanceUpdate.vue"; 
import Sale from "@/pages/Sale.vue";
import ExtraCost  from "@/pages/ExtraCost.vue";
import ExtraCostUpdate from "@/pages/ExtraCostUpdate.vue";
import SidebarLogoutButton from "@/components/SidebarLogoutButton.vue";
const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
    children: [
      {
        path: "login",
        component: LoginLayout, // Use the new login layout for the login route
        children: [
          {
            path: "", // Nested routes for login page can be added here if needed
            name: "Login",
            component: Login,
            meta: { requiresAuth: false } // Allow access without authentication
          }
        ]
      },
      {
        path: "logout",
        name: "logout",
        component: SidebarLogoutButton,
        meta: { requiresAuth: true }
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { requiresAuth: true }
      },
      {
        path: '/sale/:id',
        name: 'Sale',
        component: Sale,
        props:true,
        meta: { requiresAuth: true }
      },
      {
        path: "cars",
        name: "Cars",
        component: Cars,
        meta: { requiresAuth: true }
      },
      {
        path: "caradd",
        name: "CarAdd",
        component: CarAdd,
        meta: { requiresAuth: true }
      },
      {
        path: '/cars/:id',
        name: 'CarUpdate',
        component: CarUpdate,
        props:true,
        meta: { requiresAuth: true }
      },
      {
        path: '/apk/:id',
        name: 'APK',
        component: Apk,
        props:true,
        meta: { requiresAuth: true }
      },
      {
        path: '/apk/:id/:apk_id/update',
        name: 'update-apk',
        component: ApkUpdate,
        props: true // Pass route params as props to the component
      },
      {
        path: '/extracost/:id',
        name: 'extracost',
        component: ExtraCost,
        props:true,
        meta: { requiresAuth: true }
      },
      {
        path: '/extracost/:id/:extracost_id/update',
        name: 'update-extra-cost',
        component: ExtraCostUpdate,
        props: true // Pass route params as props to the component
      },
      {
        path: '/maintenance/:id',
        name: 'Maintenance',
        component: Maintenance,
        props:true,
        meta: { requiresAuth: true }
      },
      {
        path: '/maintenance/:id/:maintenance_id/update',
        name: 'update-maintenance',
        component: MaintenanceUpdate,
        props: true // Pass route params as props to the component
      },
      {
        path: "owners",
        name: "Owners",
        component: Owners,
        meta: { requiresAuth: true }
      },
      {
        path: "add-owner",
        name: "Add Owner",
        component: OwnerAdd,
        meta: { requiresAuth: true }
      },
      {
        path: '/owners/:id',
        name: 'OwnerUpdate',
        component: OwnerUpdate,
        props:true,
        meta: { requiresAuth: true }
      },
      {
        path: "companycost",
        name: "CompanyCost",
        component: CompanyCost,
        meta: { requiresAuth: true }
      },

   /*    {
        path: "user",
        name: "User Profile",
        component: UserProfile,
        meta: { requiresAuth: true }
      },
      {
        path: "table",
        name: "",
        component: TableList,
        meta: { requiresAuth: true }
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
        meta: { requiresAuth: true }
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
        meta: { requiresAuth: true }
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,

      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: UpgradeToPRO,
      }, */
    ],
  },
];

export default routes;
