<template>
  <form @submit.prevent="addUpdateMaintenance">
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">{{ isNewMaintenance ? 'Add New Maintenance' : 'Update Maintenance Details' }}</h4>
        <p class="category">Maintenance</p>
      </md-card-header>
      <div class="empty-row"></div>
      <md-card-content>
        <div class="md-layout">
          <input type="hidden" v-model="car_id" />
          <!-- Maintenance Date -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label class="always-above">Maintenance Date</label>
              <md-input v-model="maintenance_date" type="date"></md-input>
            </md-field>
          </div>

          <div class="empty-row"></div>

          <!-- Kilometers -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Kilometers</label>
              <md-input v-model="km" type="number"></md-input>
            </md-field>
          </div>
          
          <!-- Cost -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Cost</label>
              <md-input v-model="cost" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Customer Price -->
          <div v-if="carType == 'For Service'" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Customer Price</label>
              <md-input v-model="customer_price" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Taken Money -->
          <div v-if="carType == 'For Service'" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Taken Money</label>
              <md-input v-model="taken_money" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Notes -->
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>Notes</label>
              <md-textarea v-model="notes" rows="3"></md-textarea>
            </md-field>
          </div>
          
          <!-- Submit Button -->
          <div class="md-layout-item md-size-100 text-right">
            <md-button type="submit" class="md-raised md-success add-button">{{ isNewMaintenance ? 'Add Maintenance' : 'Update Maintenance' }}</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { API_URLS } from '@/apiConfig.js'; 
export default {
  name: "maintenance-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "#484848",
    },
    isNewMaintenance: {
      type: Boolean,
      default: true,
    },
    carType: {
      type: String,
      default: '', // Default value in case it's not provided
    },
  },
  data() {
    return {
      car_id: null,
      maintenance_date: null,
      km: null,
      cost: null,
      customer_price: null,
      taken_money: null,
      notes: null,
    };
  },
  async created() {
    if (!this.isNewMaintenance) {
      this.fetchMaintenanceDetails();
    }
  },
  methods: {
    async fetchMaintenanceDetails() {
      try {
        const maintenance_id = this.$route.params.maintenance_id;
        const token = localStorage.getItem('accessToken');

        const apiUrl=API_URLS.getMaintenanceById(maintenance_id)
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Maintenance details');
        }
        
        const data = await response.json();

        this.car_id = data.car_id;
        this.maintenance_date = data.maintenance_date;
        this.km = data.km;
        this.cost = data.cost;
        this.customer_price = data.customer_price;
        this.taken_money = data.taken_money;
        this.notes = data.notes;
            
      } catch (error) {
        //console.error('Error fetching Maintenance details:', error.message);
      }
    },

    async addUpdateMaintenance() {

      // Check if maintenance_date is blank or null
      if (!this.maintenance_date || this.maintenance_date === '') {
        this.$swal({
          title: 'Error',
          text: 'Date cannot be blank',
          icon: 'error',
          timer: 2000, // Dialog will close after 2 seconds
          showConfirmButton: false // Hide the confirm button
        });
        return;
      }

      // Check if cost is blank or null
      if (!this.cost || this.cost === '') {
        this.$swal({
          title: 'Error',
          text: 'Cost cannot be blank',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        return;
      }


      const car_id = this.$route.params.id;
      const maintenance_id = this.$route.params.maintenance_id;

      const token = localStorage.getItem('accessToken');
   /*    console.log(JSON.stringify({
            car_id: car_id,
            maintenance_date: this.maintenance_date,
            km: this.km,
            cost: this.cost,
            customer_price: this.customer_price,
            taken_money: this.taken_money,
            notes: this.notes,
          })) */
      try {
        let response;
        let apiUrl;
        let method;

        if (this.isNewMaintenance) {
          apiUrl = API_URLS.efeCreateNewMaintenance(car_id);
          method = 'POST';
        } else {
          apiUrl = API_URLS.efeUpdateCarMaintenance(maintenance_id);
          method = 'PATCH';
        }

        response = await fetch(apiUrl, {
          method: method,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            car_id: car_id,
            maintenance_date: this.maintenance_date,
            km: this.km,
            cost: this.cost,
            customer_price: this.customer_price,
            taken_money: this.taken_money,
            notes: this.notes,
          }),
        });

        if (!response.ok) {
          throw new Error(this.isNewMaintenance ? 'Failed to add Maintenance' : 'Failed to update Maintenance');
        }

        const data = await response.json();
        //console.log(this.isNewMaintenance ? 'Maintenance added successfully:' : 'Maintenance updated successfully:', data);
        if(this.isNewMaintenance){

          this.$swal({
            title: 'Success',
            text: 'Maintenance added successfully!',
            icon: 'success',
            timer: 2000, // Dialog will close after 2 seconds
            showConfirmButton: false // Hide the confirm button
          });
          // Wait for 2 seconds before reloading the page
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }

        if(!this.isNewMaintenance){
          this.$swal({
            title: 'Success',
            text: 'Maintenance updated successfully!',
            icon: 'success',
            timer: 2000, // Dialog will close after 2 seconds
            showConfirmButton: false // Hide the confirm button
          });
          // Wait for 2 seconds before reloading the page
          setTimeout(() => {
            this.$router.push(`/maintenance/${car_id}`);
          }, 2000);
          
        }
      } catch (error) {
        //console.error('Error:', error.message);
      }
    },
  },
};
</script>

<style scoped>
.always-above {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

button {
  background-color: #484848;
}

form .md-button.md-raised.md-success.add-button {
  background-color: #484848 !important;
}

.empty-row {
  height: 40px;
}

.custom-dropdown {
  width: 100%;
}
</style>
