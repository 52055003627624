<template>
    <div class="login-layout">
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .login-layout {
    /* Add your custom styles for the login layout */
  }
  </style>
  