<template>
  <form @submit.prevent="addUpdateAPK">
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title"> {{ isNewAPK ? 'Add New APK' : 'Update APK Details' }} </h4>
        <p class="category">MAK</p>
      </md-card-header>
      <div class="empty-row"></div>
      <md-card-content>
        <div class="md-layout">
          <input type="hidden" v-model="car_id" />
          <!-- APK Date -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label class="always-above">APK Date</label>
              <md-input v-model="apk_date" type="date"></md-input>
            </md-field>
          </div>

          <div class="empty-row"></div>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <label>APK Valid</label>
            <div class="select-wrapper">
              <v-select v-model="apk_valid" :options="['Yes', 'No']" class="custom-dropdown"></v-select>
            </div>
          </div>
          <div class="empty-row"></div>
  
          <!-- Kilometers -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Kilometers</label>
              <md-input v-model="km" type="number"></md-input>
            </md-field>
          </div>
          
          <!-- Cost -->
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Cost</label>
              <md-input v-model="cost" type="number" step="any" ></md-input>
            </md-field>
          </div>
          
          <!-- Taken Money -->
          <div v-if="carType == 'For Service'" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Taken Money</label>
              <md-input v-model="taken_money" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Customer Price -->
          <div v-if="carType == 'For Service'" class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Customer Price</label>
              <md-input v-model="customer_price" type="number" step="any"></md-input>
            </md-field>
          </div>
          
          <!-- Notes -->
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>Notes</label>
              <md-textarea v-model="notes" rows="3"></md-textarea>
            </md-field>
          </div>
          
          <!-- Submit Button -->
          <div class="md-layout-item md-size-100 text-right">
            <md-button type="submit" class="md-raised md-success add-button">{{ isNewAPK ? 'Add APK' : 'Update APK' }}</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>


<script>
import vSelect from 'vue-select';
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "apk-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "#484848",
    },
    isNewAPK: {
      type: Boolean,
      default: true, // Default value in case it's not provided
    },
    carType: {
      type: String,
      default: '', // Default value in case it's not provided
    },

  },

  components: {
    vSelect,
  },
  data() {
    return {
      car_id: null,
      apk_date: null,
      apk_valid: null,
      km: null,
      cost: null,
      customer_price: null,
      taken_money: null,
      notes: null,
      
    };
  },

   created() {

    //console.log("car type")
    //console.log(this.carType)
    if (this.isNewAPK==false) {
      // If carId is provided, fetch car details for updating
      this.fetchApkDetails();
    } else {
      
    }
    },
  methods: {

    


    async fetchApkDetails() {
  try {
    const id = this.$route.params.apk_id;
    // Get the JWT token from wherever you have stored it (e.g., localStorage)
    const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

    const apiUrl=API_URLS.getApkByApkID(id)
    const response = await fetch(apiUrl, {
      headers: {
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch APK details');
    }
    
    const data = await response.json();

    // Populate apkDetails with fetched data
    this.car_id = data.car_id;
    this.apk_date = data.apk_date;
    this.apk_valid = data.apk_valid;
    this.km = data.km;
    this.cost = data.cost;
    this.customer_price = data.customer_price;
    this.taken_money = data.taken_money;
    this.notes = data.notes;

    // Further actions if needed based on fetched APK details
        
  } catch (error) {
    ////console.error('Error fetching APK details:', error.message);
  }
},

  async addUpdateAPK(isNewAPK) {

  // Check if apk_date is blank or null
  this.$swal({
    title: 'Error',
    text: 'Apk Date cannot be blank',
    icon: 'error',
    timer: 2000, // Dialog will close after 2 seconds
    showConfirmButton: false // Hide the confirm button
  });
  if (!this.apk_date || this.apk_date === '') {
    return;
  }

  // Check if apk_valid is blank or null
  this.$swal({
    title: 'Error',
    text: 'Apk Valid cannot be blank',
    icon: 'error',
    timer: 2000,
    showConfirmButton: false
  });
  if (!this.apk_valid || this.apk_valid === '') {
    return;
  }

  // Check if cost is blank or null
  this.$swal({
    title: 'Error',
    text: 'Cost cannot be blank',
    icon: 'error',
    timer: 2000,
    showConfirmButton: false
  });
  if (!this.cost || this.cost === '') {
    return;
  }



    const car_id = this.$route.params.id;
    const apk_id = this.$route.params.apk_id;

    const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation
    try {
      let response;
      let apiUrl;
      let method;

  

      if (this.isNewAPK) {
        // If it's a new APK, use POST method and the create APK endpoint
        apiUrl = API_URLS.efeCreateNewApk(car_id);
        method = 'POST';
      } else {
        // If it's an update to an existing APK, use PATCH method and the update APK endpoint
        apiUrl = API_URLS.efeUpdateCarApk(apk_id);;
        method = 'PATCH';
      }

      response = await fetch(apiUrl, {
        method: method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          car_id: car_id,
          apk_date: this.apk_date,
          apk_valid: this.apk_valid,
          km: this.km,
          cost: this.cost,
          customer_price: this.customer_price,
          taken_money: this.taken_money,
          notes: this.notes,
        }),
      });

      if (!response.ok) {
        throw new Error(this.isNewAPK ? 'Failed to add APK' : 'Failed to update APK');
      }

      const data = await response.json();
      //console.log(this.isNewAPK ? 'APK added successfully:' : 'APK updated successfully:', data);
      




      if(this.isNewAPK){
 // If it's a new APK, show a success message with SweetAlert
 this.$swal({
        title: 'Success',
        text: 'APK added successfully!',
        icon: 'success',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      // Wait for 2 seconds before reloading the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      }
      

      if(!this.isNewAPK){
        this.$swal({
        title: 'Success',
        text: 'APK updated successfully!',
        icon: 'success',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      // Wait for 2 seconds before reloading the page
      setTimeout(() => {
        this.$router.push(`/apk/${car_id}`);
      }, 2000);
       
      }


      
    } catch (error) {
      ////console.error('Error:', error.message);
    }
  },
  },
};
</script>

<style scoped>
.always-above {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease; /* Optional: Add transition for smooth movement */
}

/* Target all buttons */
button {
  background-color: #484848; /* antrasit color */
}
/* Override button color with !important */
form .md-button.md-raised.md-success.add-button {
  background-color: #484848 !important; /* antrasit color */
}
.empty-row {
  height: 40px; /* Adjust the height as needed */
}
.custom-dropdown {
  width: 100%; /* Set the width to 100% or adjust as needed */
}
/* Styles... */
</style>
