<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications v-if="!isLoginPage"></notifications>

    <side-bar
      v-if="!isLoginPage"
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>
      <sidebar-link to="/cars">
        <md-icon>directions_car</md-icon>
        <p> Manage Cars</p>
      </sidebar-link>
      <sidebar-link to="/owners">
        <md-icon>person</md-icon>
        <p> Manage Owners</p>
      </sidebar-link>
      <sidebar-link to="/companycost">
        <md-icon>content_paste</md-icon>
        <p> Company Cost</p>
      </sidebar-link>
      <!-- Add Logout Button -->
      <sidebar-link to="/logout">
          <md-icon>exit_to_app</md-icon>
          <p> Logout</p>
        </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar v-if="!isLoginPage"></top-navbar>

      <fixed-plugin
        v-if="!isLoginPage"
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <router-view></router-view>

      <content-footer v-if="!isLoginPage && !$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
//import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";


export default {
  components: {
    TopNavbar,
   
    ContentFooter,
    MobileMenu,
    FixedPlugin,
  },
  data() {
    return {
      sidebarBackground: "'#484848'",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
    };
  },
  computed: {
    isLoginPage() {
      return this.$route.path === '/login';
    }
  },
  methods: {

  },
};
</script>


