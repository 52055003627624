<template>
  <form @submit.prevent="addCompanyCost">
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">Add Company Cost</h4>
        <p class="category">Add New Company Cost</p>
      </md-card-header>
      <div class="empty-row"></div>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label class="always-above">Cost Date</label>
              <md-input v-model="cost_date" type="date"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Cost</label>
              <md-input v-model="cost" type="number" step="any"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>Notes</label>
              <md-textarea v-model="notes" rows="3"></md-textarea>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button type="submit" class="md-raised md-success add-button">Add Company Cost</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { API_URLS } from '@/apiConfig.js';
export default {
  name: "company-cost-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "#484848",
    },
  },
  data() {
    return {
      cost_date: null, // changed to cost_date
      cost: null,
      notes: null,
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign) {
      var color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        message:
          "Succesfull",
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
      });
    },
    async addCompanyCost() {

    // Check if cost_date is blank or null
    this.$swal({
      title: 'Error',
      text: 'Date cannot be blank',
      icon: 'error',
      timer: 2000, // Dialog will close after 2 seconds
      showConfirmButton: false // Hide the confirm button
    });
    if (!this.cost_date || this.cost_date === '') {
      return;
    }

    // Check if cost is blank or null
    this.$swal({
      title: 'Error',
      text: 'Cost cannot be blank',
      icon: 'error',
      timer: 2000,
      showConfirmButton: false
    });
    if (!this.cost || this.cost === '') {
      return;
    }



      // Get the JWT token from wherever you have stored it (e.g., localStorage)
      const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

      try {
        const apiUrl=API_URLS.addcompanycosts
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cost_date: this.cost_date,
            cost: this.cost,
            notes: this.notes,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to add company cost');
        }

        // If successful, you can handle the response as needed
        const data = await response.json();
        //console.log('Company cost added successfully:', data);
        //this.notifyVue('top', 'center');
        this.$swal({
        title: 'Success',
        text: 'Company cost added successfully!',
        icon: 'success',
        timer: 2000, // Dialog will close after 2 seconds
        showConfirmButton: false // Hide the confirm button
      });
      // Wait for 2 seconds before reloading the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
        
        // You can perform any additional actions here, such as showing a success message or navigating to another page.
      } catch (error) {
        ////console.error('Error adding company cost:', error.message);
        // Handle the error, such as displaying an error message to the user.
      }
    },

  },
};
</script>

<style scoped>
.always-above {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease; /* Optional: Add transition for smooth movement */
}

/* Target all buttons */
button {
  background-color: #484848; /* antrasit color */
}
/* Override button color with !important */
form .md-button.md-raised.md-success.add-button {
  background-color: #484848 !important; /* antrasit color */
}
.empty-row {
  height: 40px; /* Adjust the height as needed */
}
</style>
