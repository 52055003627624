<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <maintenance-form :dataBackgroundColor="'#484848'" :isNewMaintenance="false" :carType="carType"></maintenance-form>
      </div>
    </div>
  </div>
</template>

<script>
import MaintenanceForm from "@/pages/MaintenanceForm.vue";
import { API_URLS } from '@/apiConfig.js';
export default {
  components: {
    MaintenanceForm,
  },
  data() {
    return {
      carType: null, // Initialize carType to null
    };
  },
  created() {
    this.fetchCarDetails(); // Fetch car details when the component is created
  },
  methods: {
    async fetchCarDetails() {
      try {
        // Your code to fetch car details
        const carId = this.$route.params.id;
        const token = localStorage.getItem('accessToken');
        const apiUrl = API_URLS.efeGetCarById(carId);
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch car details');
        }

        const data = await response.json();
        this.carType = data.car_type; // Set the fetched car_type to the data property
      } catch (error) {
        //console.error('Error fetching car details:', error.message);
      }
    },
  },
};
</script>

<style scoped>


.add-button {
  margin-bottom: 20px;
}
</style>
