<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Sale Information</h4> <!-- Updated title -->
          </md-card-header>
          <md-card-content>
            <!-- Display car details -->
            <div v-if="Object.keys(carDetails).length > 0">
              <p>License Plate: {{ carDetails.license_plate }}</p>
              <p>Total APK Cost: {{ carDetails.total_apk_cost.toFixed(2) }}</p>
              <p>Total Maintenance Cost: {{ carDetails.total_maintenance_cost.toFixed(2) }}</p>
              <p>Total Extra Cost: {{ carDetails.total_extra_cost.toFixed(2) }}</p>
              <!-- Display total sum of all costs -->
              <p>Total: {{ getTotalSumOfCosts }}</p>
            </div>
            <!-- If data is still loading, display a loading message -->
            <div v-else>
              <p>Loading...</p>
            </div>
          </md-card-content>
        </md-card>
        
        <sale-form :dataBackgroundColor="'#484848'"></sale-form>
        
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Sale</h4>
            <p class="category">APKs</p>
          </md-card-header>
          <md-card-content>
            <apk-table table-header-color="'#484848'" :isSaleTable="true"></apk-table>
          </md-card-content>
        </md-card>
        
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Sale</h4>
            <p class="category">Maintenances</p>
          </md-card-header>
          <md-card-content>
            <maintenance-table table-header-color="'#484848'" :isSaleTable="true"></maintenance-table>
          </md-card-content>
        </md-card>
        
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Sale</h4>
            <p class="category">Extra Costs</p>
          </md-card-header>
          <md-card-content>
            <extra-cost-table table-header-color="'#484848'" :isSaleTable="true"></extra-cost-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>


<script>
import ApkTable from "@/components/Tables/ApkTable.vue";
import MaintenanceTable from "@/components/Tables/MaintenanceTable.vue";
import ExtraCostTable from "@/components/Tables/ExtraCostTable.vue";
import SaleForm from "@/pages/SaleForm.vue";
import { API_URLS } from '@/apiConfig.js';

export default {
  components: {
    ApkTable,
    SaleForm,
    ExtraCostTable,
    MaintenanceTable,
  },
  data() {
    return {
      carDetails: {}, // Initialize an empty object to store car details
    };
  },
  mounted() {
    // Fetch car details from backend when the component is mounted
    this.fetchCarDetails();
  },
  computed: {
    // Calculate the total sum of all costs
    getTotalSumOfCosts() {
      const total = this.carDetails.total_apk_cost + this.carDetails.total_maintenance_cost + this.carDetails.total_extra_cost;
      return total.toFixed(2);
    },
  },
  methods: {
    async fetchCarDetails() {
      // Get the JWT token from wherever you have stored it (e.g., localStorage)
      const token = localStorage.getItem('accessToken'); // Adjust this according to your implementation

      try {
        const carId = this.$route.params.id;
        const apiUrl = API_URLS.getCarDetailsForSale(carId); // Adjust the API URL function as needed
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch car details');
        }
        
        const data = await response.json();
        this.carDetails = data;
      } catch (error) {
        //console.error('Error fetching car details:', error.message);
      }
    },
  }
};
</script>

<style scoped>
.add-button {
  margin-bottom: 20px;
}
</style>
