<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-button class="add-button" @click="goToAddNewOwnerPage" md-raised-button md-primary>Add New Owner</md-button>
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Owners</h4>
            <p class="category">It displays owners</p>
          </md-card-header>
          <md-card-content>
            <owner-table table-header-color="'#484848'"></owner-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import OwnerTable from "@/components/Tables/OwnerTable.vue";
export default {
  components: {
    OwnerTable,
  },
  methods: {
    goToAddNewOwnerPage() {
      // Add logic to navigate to the "Add New Owner" page
      // Example: 
      this.$router.push('add-owner'); // Assuming '/owners' is the route to the owners page
    },
  },
};
</script>

<style scoped>
.add-button {
  margin-bottom: 20px;
}
</style>
