<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-button class="add-button" @click="goToAddNewCarPage" md-raised-button md-primary>Add New Car</md-button>
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Cars For Sale</h4>
            <p class="category">It displays cars for sale cars</p>
          </md-card-header>
          <md-card-content>
            <car-table table-header-color="'#484848'" :tableType="'tableForSale'"></car-table>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Cars For Service</h4>
            <p class="category">It displays cars for Service</p>
          </md-card-header>
          <md-card-content>
            <car-table table-header-color="'#484848'" :tableType="'tableForService'"></car-table>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-header data-background-color="'#484848'">
            <h4 class="title">Saled Cars</h4>
            <p class="category">It displays saled cars </p>
          </md-card-header>
          <md-card-content>
            <car-table table-header-color="'#484848'" :tableType="'tableSaledCars'"></car-table> 
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import CarTable from "@/components/Tables/CarTable.vue";
export default {
  components: {
    CarTable,
  },
  methods: {
    goToAddNewCarPage() {
      // Add logic to navigate to the "Add New Car" page
      // Example: 
      this.$router.push('caradd'); // Assuming '/cars' is the route to the cars page
    },
  },
};
</script>

<style scoped>
.add-button {
  margin-bottom: 20px;
}
</style>
